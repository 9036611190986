import * as React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@material-ui/core';
import { MyDialogUser } from "../Dialog/MyDialogUser";
import { ErrorMessage } from "../Common/ErrorMessage";

type Props = {
  isUserDialogOpen: boolean,
  setUserDialogOpen: (isOpen: boolean) => void,
  onUserChange: (userId: number) => void,
  isUserError: boolean,
  userId: number,
  error: string,
  onClose: () => void
}

export const WizardStepUserDialog: React.FC<Props> = ({
                                                        isUserDialogOpen, setUserDialogOpen,
                                                        onClose, error, isUserError, onUserChange,
                                                        userId
                                                      }) => {
  const onSubmit = () => {
    setUserDialogOpen(false);
  }

  return (
    <Dialog
      maxWidth={'xs'}
      fullWidth
      open={isUserDialogOpen}
    >
      <DialogTitle>Enter User Id</DialogTitle>
      <DialogContent>
        <MyDialogUser onChange={onUserChange} userError={isUserError} user={userId} />
        <ErrorMessage message={error} isVisible={!!error} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
