import dayjs from "dayjs";
import { addBusinessDays } from '../../services/DateFormatter';

function sortOrderAndPlateNumbers(a: Plate, b: Plate) {
  if (a.order_reference_number < b.order_reference_number) {
    return -1;
  }

  if (a.order_reference_number > b.order_reference_number) {
    return 1;
  }

  return a.reference_number < b.reference_number ? -1 : 1;
}

function sortDates(a: Plate, b: Plate) {
  if (!a.delivery_date || !b.delivery_date) {
    return 0;
  }

  const dayjsDateA = dayjs(a.delivery_date);
  const dayjsDateB = dayjs(b.delivery_date);

  if (dayjsDateA.isSame(dayjsDateB)) {
    return 0;
  }

  return dayjsDateA.isAfter(dayjsDateB) ? 1 : -1;
}

function sortSinksAtTop(a: Plate) {
  if (a.types && a.types.map((type: any) => type.plate_type.toUpperCase()).some(value => value.includes("VASK"))) {
    return -1;
  }

  return 0;
}

export function sortMeasurementsRows(rows: Array<Order>) {
  return rows.sort((a, b) => {
    // Define sorting priorities
    const getPriority = (item: Order) => {
      if (item.control_measures && !item.is_estimated && !item.is_confirmed) {
        return 0;
      }

      if (item.control_measures && item.estimate_date && !item.confirm_date) {
        return 1;
      }

      if (item.control_measures && item.confirm_date) {
        return 3;
      }

      if (!item.control_measures) {
        return 4
      }

      return 5;
    };

    // Get priorities
    const aPriority = getPriority(a);
    const bPriority = getPriority(b);

    // Sort by priority first
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }

    if (a.is_confirmed) {
      const aDate = a.confirm_date;
      const bDate = b.confirm_date;
      const aTimestamp = new Date(aDate).getTime();
      const bTimestamp = new Date(bDate).getTime();
      return aTimestamp - bTimestamp;
    }

    if (a.estimate_date) {
      const aDate = a.estimate_date;
      const bDate = b.estimate_date;
      const aTimestamp = new Date(aDate).getTime();
      const bTimestamp = new Date(bDate).getTime();
      return aTimestamp - bTimestamp;
    }

    return 0;
  });
}

function getExtendRows(rows: Array<Order>) {
  return rows.map(order => {
    const { delivery_date, pickup_date, installation_date, sellerTeams: { installationAddDays } } = order;
    let conditionalDate = new Date(installation_date);
    if (!installation_date) {
      const baseDate = pickup_date || delivery_date;
      conditionalDate = addBusinessDays(baseDate, installationAddDays);
    }
    return { ...order, conditionalDate }
  });
}

export function sortFreightRows(rows: Array<Order>) {
  const extendRows = getExtendRows(rows);

  const rowsWithoutPickup = extendRows.filter(row => !row.pickup_date || !row.delivery_method);
  const rowsWithPickup = extendRows.filter(row => row.pickup_date && row.delivery_method);

  rowsWithoutPickup.sort((a, b) => new Date(a.delivery_date).getTime() - new Date(b.delivery_date).getTime());
  rowsWithPickup.sort((a, b) => new Date(a.pickup_date).getTime() - new Date(b.pickup_date).getTime());

  return [...rowsWithoutPickup, ...rowsWithPickup];
}

export function sortInstallationRows(rows: Array<Order>) {
  const extendRows = getExtendRows(rows);
  
  extendRows.sort((a, b) => a.conditionalDate.getTime() - b.conditionalDate.getTime());
  
  //legacy: old flow before 2025
  const rowsOldFlow = extendRows.filter(row => row.is_freight === null);
  const rowsNewFlow = extendRows.filter(row => row.is_freight !== null);
  //----------------------------

  const rowsWithDateAndConfirmation = rowsNewFlow.filter(row => row.installation_date && row.installation_confirmed);
  const rowsWithoutConfirmation = rowsNewFlow.filter(row => row.installation_date && !row.installation_confirmed);
  const rowsWithoutDate = rowsNewFlow.filter(row => !row.installation_confirmed && !row.installation_date);

  return [...rowsWithoutDate, ...rowsWithoutConfirmation, ...rowsWithDateAndConfirmation, ...rowsOldFlow];
}

export function sortRows(rows: any, disableSorting?: boolean) {
  if (disableSorting) {
    return rows;
  }

  return rows
    .sort(sortOrderAndPlateNumbers)
    .sort(sortSinksAtTop)
    .sort(sortDates);
}
