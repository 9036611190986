import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import { Checkbox, Theme, Typography } from '@material-ui/core';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { PLATE_TYPE } from '../../shared/constants';
import { MyDialog } from '../../components/Dialog/MyDialog';
import { WarningInfo } from '../../components/WarningInfo';

import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import {
  installationDate,
  getContactList,
  sendConfirmationDataInfo
} from '../../redux/actions/ordersActions';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import {
  formatToDate, formatToTime, formatToSaveUtcDate, formatToSaveUtcTime
} from '../../services/DateFormatter';

type InstallationConfirmDateDialogType = {
  orderId: number,
  plates: Plate[],
  referenceNumber: string,
  conditionalDate: Date,
  pickupDate: Date
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    "& > div": {
      margin: theme.spacing(1)
    }
  }
}));

export function InstallationConfirmDateDialog({ orderId, plates, referenceNumber, conditionalDate, pickupDate }: InstallationConfirmDateDialogType) {
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const contactList = useSelector((state: GlobalStoreType) => state.confirmation.contactList);

  const [_date, changeDate] = React.useState(conditionalDate);
  const [_time, changeTime] = React.useState(conditionalDate);

  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeDate = async (newDate: Date) => changeDate(newDate || new Date);
  const onChangeTime = async (newTime: Date) => changeTime(newTime);

  React.useEffect(() => {
    resetError();
  }, [user]);

  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(getContactList(referenceNumber));
    }
  }, [isOpen]);

  if (!(isAdmin || isMonter)) {
    return null;
  }

  function resetLocalState() {
    resetUser();
  }

  async function submitConfirmOrder() {
    try {
      const timestamp = `${formatToSaveUtcDate(_date)} ${formatToSaveUtcTime(_time)}`;
      await installationDate(orderId, { user_id: user, date: timestamp, confirmed: true });

      const type = "installation";
      const confirmDate = formatToDate(_date);
      const confirmTime = formatToTime(_time);

      const phoneNumberList = contactList.filter(contact => contact.toSend).map(contact => contact.telefon);
      dispatch(sendConfirmationDataInfo(referenceNumber, { type, phoneNumberList, confirmDate, confirmTime }));

      resetLocalState();
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const freightConfirmed = !!pickupDate || plates.some(plate => (plate.current_stage !== PLATE_TYPE.COMPLETED) && plate.has_freight);

  return (
    <MyDialog
      buttonLabel={formatToDate(conditionalDate)}
      dialogTitle={"Confirm"}
      onEnter={() => setOpen(true)}
      onCancel={() => setOpen(false)}
      customColor={'yellow'}
      variant={'contained'}
      onSubmit={submitConfirmOrder}
      onSubmitClose
      onSubmitDisabled={!freightConfirmed}
      modalSize="xs"
    >
      <div className={classes.root}>
        {!freightConfirmed && <WarningInfo>Freight is not confirmed - installation date can not be set</WarningInfo>}
        {!contactList && <strong>LOADING...</strong>}
        {contactList?.length > 0 && (contactList.map((contact: Contact, index: number) =>
          <div>
            <Checkbox
              key={index}
              color="primary"
              onClick={() => contact.toSend = true}
            />{contact.rolle} - {contact.navn} - {contact.telefon}
          </div>
        ))}
        {contactList.length == 0 && <strong>NO CONTACTS</strong>}
        <div className={classes.row}>
          <Typography variant="subtitle2">Confirm time of installation</Typography>
        </div>
        <div className={classes.row}>
          <DatePicker
            fullWidth
            animateYearScrolling
            variant="outlined"
            label="Date"
            value={_date}
            minDate={conditionalDate}
            onChange={onChangeDate}
            format="dd-MM-yyyy"
          />
          <TimePicker
            fullWidth
            variant="outlined"
            label="Time"
            value={_time}
            onChange={onChangeTime}
            format="HH:mm"
            ampm={false}
          />
        </div>
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}
