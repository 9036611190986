import { useSelector } from "react-redux";
import * as React from "react";
import HouseIcon from "@material-ui/icons/House";
import { Avatar, Chip, Theme } from "@material-ui/core";
import { EventBadge } from "./EventBadge";
import { EventInformation } from "./EventInformation";
import { makeStyles } from "@material-ui/styles";
import { selectDeliveryMethods } from "../../redux/selectors";

export const EventComponent = ({ event }: any) => {
  const sellerTeams = useSelector((state: GlobalStoreType) => state.sellerTeams);
  const deliveryMethodsDictionary = useSelector(selectDeliveryMethods);
  const [isOpen, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const installationTeamName = React.useMemo(() => sellerTeams
      .allInstallationTeams
      .find(team => team._id === event.sellerTeams.installationId)?.name ?? "<no match team>",
    [event.sellerTeams.installationId, sellerTeams]);

  const deliveryMethods = React.useMemo(() => [...new Set(event.plates?.map(e => deliveryMethodsDictionary[e.delivery_method]))]
      .join(", "),
    [event.plates, deliveryMethodsDictionary]);

  return (
    <React.Fragment>
      <div onClick={handleOpen}>
        <strong>{event.title}:</strong>
        <span>&ensp;{event.material.name} - {event.material.type} {event.material.thickness}</span>
      </div>
      <div className="event-types">
        {event.types && event.types.map((type: PlateTypes) => (
          <div key={type.id} className={"event-chip"}>
            {type.glue_sink && (
              <HouseIcon
                style={{ color: type.in_stock ? "green" : "red", verticalAlign: "middle" }}
                stroke={"white"}
                cstroke-width={1}
              />)}
            <Chip
              size="small"
              color={type.in_stock ? "primary" : "secondary"}
              key={type.plate_type}
              label={type.plate_type}
              avatar={<Avatar>{type.count}</Avatar>}
            />
          </div>
        ))}
      </div>
      <div>
        {event.plates?.length && event.plates.map((plate: Plate) => (
          <EventBadge key={plate.id} plate={plate} />
        ))}
      </div>
      <div className={classes.deliveryMethod}>{deliveryMethods}</div>
      <div className={classes.installationTeam}>
        <span>
          <strong>Installation team:</strong> {installationTeamName}
        </span>
      </div>
      <EventInformation
        isOpen={isOpen}
        handleClose={handleClose}
        event={event}
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  deliveryMethod: {
    marginTop: theme.spacing(1),
  },
  installationTeam: {
    marginTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  }
}));
