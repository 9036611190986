import * as React from 'react';
import { Avatar, Chip, Theme, useMediaQuery } from '@material-ui/core';

import HouseIcon from '@material-ui/icons/House';
import FlagIcon from '@material-ui/icons/Flag';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useSelector, useDispatch } from 'react-redux';
import { setDeliveryMethod } from '../../redux/actions/ordersActions';

import { DataTableCanceledOrder } from '../../components/DataTable/DataTableCanceledOrder';
import { DataTableDate } from '../../components/DataTable/DataTableDate';
import { DataTableFaultyOrder } from '../../components/DataTable/DataTableFaultyOrder';

import { InlineDictionarySelect } from "../../components/Orders/InlineDictionarySelect";

import { DataTableInformationFault } from '../../components/DataTableInformation/DataTableInformationFault';
import { PlateBadge } from '../../components/PlateBadge';
import { PreventPropagation } from '../../components/PreventPropagation';
import { formatToDateWithTime } from '../../services/DateFormatter';
import { getActionType } from './MetaDataTableActionProvider';
import { COMMENT_TYPE, ORDER_TYPE, PLATE_TYPE } from '../../shared/constants';
import { DataTableCostCell } from '../../components/DataTableCells/DataTableCostCell';
import { DataTableUserCell } from '../../components/DataTableCells/DataTableUserCell';
import { DataTableFaultByCell } from '../../components/DataTableCells/DataTableFaultByCell';
import { ContentRow } from '../../components/Common/ContentRow';
import { Button } from '../../components/Button';
import { DataTableDeviationImages } from '../../components/DataTable/DataTableDeviationImages';
import { DataTableInvoicedCell } from '../../components/DataTableCells/DataTableInvoicedCell';
import { formatToDate, formatHours } from '../../services/DateFormatter';

import akemi from '../../assets/AKEMI_logo.png';

const useStyles = makeStyles(() => ({
  overflow: {
    overflow: 'visible'
  }
}));

const MetaReferenceNumberCell = ({ children, data, isPlate }: any) => {
  const fault: CommentType = data.comments && data.comments.find((c: CommentType) => c.comment_type === COMMENT_TYPE.FAULT);
  const faultText = fault ? fault.comment_text : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isPlate && <DataTableCanceledOrder cancelDate={data.cancel_date} />}
      {isPlate && data.fault_plate_id &&
        <DataTableFaultyOrder fault_plate_id={data.fault_plate_id} fault={faultText} />}
      {children}
    </div>
  );
};

const MetaContentReferenceNumberCell = ({ reference_number }: { reference_number: string }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {reference_number}
  </div>
);

const LinkContentReferenceNumberCell = ({ data, children }: {
  data: Plate & Order,
  children: JSX.Element | JSX.Element[]
}) => {
  const isPlate = !!data.order_reference_number;
  const baseUrl = useSelector((state: GlobalStoreType) => state.configuration.orderBaseUrl);

  if (isPlate) {
    return (
      <PlateContentReferenceNumberCell data={data} baseUrl={baseUrl}>
        {children}
      </PlateContentReferenceNumberCell>
    );
  }

  return (
    <OrderContentReferenceNumberCell data={data} baseUrl={baseUrl}>
      {children}
    </OrderContentReferenceNumberCell>
  );
};

const OrderContentReferenceNumberCell = ({ data, children, baseUrl }: {
  data: Order,
  baseUrl: string,
  children: JSX.Element | JSX.Element[]
}) => {
  const offerVersion = data.offer_version || data.offerVersion;
  const orderAppLink = `${baseUrl}/#/ordre/${data.reference_number}/${offerVersion}`;

  return (
    <a href={orderAppLink} target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
      {children}
    </a>
  );
};

const PlateContentReferenceNumberCell = ({ data, children, baseUrl }: {
  data: Plate,
  baseUrl: string,
  children: JSX.Element | JSX.Element[]
}) => {
  const order = useSelector((state: GlobalStoreType) => {
    const element = state.orders.entities[data.order_id || data.id]
    element.offerVersion = state.orders.newOrdersInfoEntities[element.reference_number as any]?.offerVersion;
    return element;
  });

  const orderAppLink = `${baseUrl}/#/ordre/${order?.reference_number}/${order?.offer_version || order?.offerVersion}`;

  return (
    <a href={orderAppLink} target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
      {children}
    </a>
  );
};

const PlateOrderReferenceNumberCell = (data: Plate & Order) => {
  if (data.order_reference_number) {
    return (
      <PreventPropagation>
        <MetaReferenceNumberCell data={data} isPlate>
          <MetaContentReferenceNumberCell reference_number={data.reference_number} />
        </MetaReferenceNumberCell>
      </PreventPropagation>
    );
  }

  return (
    <PreventPropagation>
      <MetaReferenceNumberCell data={data}>
        <LinkContentReferenceNumberCell data={data}>
          <MetaContentReferenceNumberCell reference_number={data.reference_number} />
        </LinkContentReferenceNumberCell>
      </MetaReferenceNumberCell>
    </PreventPropagation>
  );
};

export const OrderReferenceNumberCell = (data: Plate & Order & { withFlag: boolean }) => {
  const order = useSelector((state: GlobalStoreType) => state.orders.entities[data?.order_id || data?.id]);
  const referenceNumber = data?.order_reference_number || order?.reference_number;
  const colorFlag = data.withFlag ? data?.complexityFlag || order?.complexityFlag : false;
  return (
    <PreventPropagation>
      <MetaReferenceNumberCell data={data}>
        <LinkContentReferenceNumberCell data={data}>
          <MetaContentReferenceNumberCell reference_number={referenceNumber} />
        </LinkContentReferenceNumberCell>
        {colorFlag && <FlagIcon style={{ color: colorFlag, stroke: "#000000", strokeWidth: 1 }}></FlagIcon>}
      </MetaReferenceNumberCell>
    </PreventPropagation>
  );
};


const PlateCell = ({ plates, actionType }: any) => {
  const installationNotAllowed = actionType === "installation";
  const anyPlateFreight = plates?.some((plate: Plate) => plate.has_freight && PLATE_TYPE.COMPLETED === plate.current_stage);
  const status = anyPlateFreight ? ORDER_TYPE.INSTALLATION : ORDER_TYPE.PRODUCTION;
  const faultNotAllowed = installationNotAllowed || status === ORDER_TYPE.INSTALLATION;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {plates?.map((plate: Plate) => faultNotAllowed ? (
        <PlateBadge
          key={plate.id}
          actionType={actionType}
          plate={plate}
        />
      ) : (
        <DataTableInformationFault
          files={plate.files}
          key={plate.id}
          plate_id={plate.id}
          order_id={plate.order_id}
          reference_number={plate.reference_number}
          custom={
            <PlateBadge
              key={plate.id}
              actionType={actionType}
              plate={plate}
            />
          }
        />
      ))}
    </div>
  );
}

export const TypesCell = ({ types = [], ...rest }: any) => {
  const nepChip = rest.no_edge_polish && (
    <Chip color="primary" key="nep" label="No Edge Polish" style={{ margin: '4px 0' }} />
  );
  const nmfChip = rest.no_manual_finish && (
    <Chip color="primary" key="nmf" label="No Manual Finish" style={{ margin: '4px 0' }} />
  );
  const epocChip = rest.edge_polish_on_cnc && (
    <Chip color="primary" key="nmf" label="Edge Polish on CNC" style={{ margin: '4px 0' }} />
  )

  return types.map((type: PlateTypes) => (
    <div style={{ whiteSpace: 'nowrap' }}>
      {type.glue_sink && (
        <HouseIcon style={{ color: type.in_stock ? "green" : "red", verticalAlign: "middle" }} />
      )}
      <Chip
        color={type.in_stock ? "primary" : "secondary"}
        key={type.plate_type}
        label={type.plate_type}
        avatar={<Avatar>{type.count}</Avatar>}
        style={{ margin: '4px 0' }}
      />
    </div>
  )).concat([nepChip, nmfChip, epocChip].filter(c => c));
};

const FaultDate = ({ fault_at }: any) => (
  <React.Fragment>
    {formatToDateWithTime(fault_at)}
  </React.Fragment>
);


export const Cell = (props: any) => {
  const { row: { actionType, checkpoints, ...data } } = props;
  const { column } = props;

  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const deliveryMethods = useSelector((state: GlobalStoreType) => state.dictionary.deliveryMethodsDictionary);
  const deliveryMethodDictionary = deliveryMethods.reduce((acc: Record<string, string>, method) => ({
    ...acc,
    [method.value]: method.text
  }), {});

  const transportCompanies = useSelector((state: GlobalStoreType) => state.dictionary.transportCompaniesDictionary);
  const transportCompanyDictionary = transportCompanies.reduce((acc: Record<string, string>, company) => ({
    ...acc,
    [company.value]: company.text
  }), {});

  const dispatch = useDispatch();

  const submitDeliveryMethod = (newMethod: string) => {
    dispatch(setDeliveryMethod(data.id, newMethod));
  }


  if (column.name === 'order_reference_number') {
    return (
      <Table.Cell {...props} onClick={(evt: React.MouseEvent<HTMLDivElement>) => evt.stopPropagation()}>
        <OrderReferenceNumberCell {...data} />
      </Table.Cell>
    );
  }

  if (column.name === 'reference_number') {
    if (data.isExtraInfo) {
      return (
        <Table.Cell {...props} colspan={2}>
          <ContentRow label="Address">{data.deliveryAddress || '<missing information>'}</ContentRow>
          <ContentRow label="Status material">{data.materialStatus || '<not registered>'}</ContentRow>
          {data.cutouts?.map((cutout: any) =>
            <Chip size="small"
                  color={cutout.inStock ? "primary" : "secondary"}
                  key={cutout.key}
                  label={cutout.label}
                  avatar={<Avatar>{cutout.count}</Avatar>}
                  style={{ margin: '4px 0' }}
            />
          )}
          {data.craneRequired && (<ContentRow label="Crane required">{data.craneRequired}</ContentRow>)}
          {data.extraHelpForLift && (<ContentRow label="Extra help for lift">{data.extraHelpForLift}</ContentRow>)}
          {data.estimatedInstallationTime && (
            <ContentRow label="Estimated installation time">{data.estimatedInstallationTime}</ContentRow>)}
        </Table.Cell>
      )
    } else {
      return (
        <Table.Cell {...props}
                    onClick={(evt: React.MouseEvent<HTMLDivElement>) => data.order_reference_number ? {} : evt.stopPropagation()}>
          <PlateOrderReferenceNumberCell {...data} />

        </Table.Cell>
      );
    }
  }

  if (column.name === 'plates') {
    return (
      <Table.Cell {...props}>
        <PlateCell {...data} actionType={actionType} />
      </Table.Cell>
    );
  }

  if (column.name === 'name') {
    if (data.isExtraInfo) {
      return (
        <Table.Cell {...props} colSpan={isTablet ? 3 : 1}>
          <ContentRow label="Address">{data.deliveryAddress || '<missing information>'}</ContentRow>
          <ContentRow label="Status material">{data.materialStatus || '<not registered>'}</ContentRow>
          {data.cutouts?.map((cutout: any) =>
            <Chip size="small"
                  color={cutout.inStock ? "primary" : "secondary"}
                  key={cutout.key}
                  label={cutout.label}
                  avatar={<Avatar>{cutout.count}</Avatar>}
                  style={{ margin: '4px 0' }}
            />
          )}
        </Table.Cell>
      );
    } else if (props.row.material?.need_impregnation || data.need_impregnation) {
      return (
        <Table.Cell {...props}>
          {props.row.name}
          <div>
            <img src={akemi} alt="AKEMI" />
          </div>
        </Table.Cell>
      );
    }
  }

  if (column.name === 'type' && data.isExtraInfo) {
    return (
      <Table.Cell {...props} colSpan={isTablet ? 2 : 1} className={classes.overflow}>
        {data.totalWeight && (<ContentRow label="Total weight">{data.totalWeight} kg</ContentRow>)}
        {data.sellerTeams && (
          <div>
            <ContentRow label="Measuring team">{data.sellerTeams.measuringName || "<unset>"}</ContentRow>
            <ContentRow label="Installation team">{data.sellerTeams.installationName || "<unset>"}</ContentRow>
          </div>
        )}
        {data.craneRequired && (<ContentRow label="Crane required">{data.craneRequired}</ContentRow>)}
        {data.extraHelpForLift && (<ContentRow label="Extra help for lift">{data.extraHelpForLift}</ContentRow>)}
        {data.estimatedInstallationTime && (
          <ContentRow label="Estimated installation time">{data.estimatedInstallationTime}</ContentRow>)}
      </Table.Cell>
    );
  }

  if (column.name === 'types') {
    return (
      <Table.Cell {...props}>
        <TypesCell {...data} />
      </Table.Cell>
    );
  }

  if (column.name === 'plate_number') {
    return (
      <Table.Cell {...props} onClick={(evt: React.MouseEvent<HTMLDivElement>) => evt.stopPropagation()}></Table.Cell>
    )
  }

  if (column.name === 'timeSinceReady' && !data.isExtraInfo) {
    const totalHours = formatHours(data.timeSinceReady?.totalHours);
    const activeHours = formatHours(data.timeSinceReady?.activeHours);
    return (
      <Table.Cell {...props} >
        <div style={{ color: totalHours.color }}>{totalHours.value}</div>
        <div style={{ color: activeHours.color }}>({activeHours.value})</div>
      </Table.Cell>
    );
  }

  if (column.name === 'pickup_date') {
    const pickupDate = data.pickup_date ? formatToDate(data.pickup_date) : null;

    return (
      <Table.Cell {...props}>{pickupDate}</Table.Cell>
    );
  }

  if (column.name === 'transport_company') {
    if (data.transport_company_id) {
      const companyName = transportCompanyDictionary[data.transport_company_id];

      return (
        <Table.Cell {...props}>{companyName}</Table.Cell>
      );
    }
  }

  if (column.name === 'delivery_date' && !data.noDeliveries) {
    const delivery_date_disable = data.order_id ? !!data.order_pickup_date : !!data.pickup_date;
    return (
      <Table.Cell {...props}>
        <DataTableDate orderId={data.order_id || data.id} plateId={data.order_id ? data.id : ''}
                       delivery_date={data.delivery_date} disable={delivery_date_disable} clearable={!data.id} />
      </Table.Cell>
    );
  }

  if (column.name === 'status' && !data.isExtraInfo) {
    const anyPlateFreight = data.plates?.some((plate: Plate) => plate.has_freight && PLATE_TYPE.COMPLETED !== plate.current_stage);
    const status = anyPlateFreight ? ORDER_TYPE.INSTALLATION : ORDER_TYPE.PRODUCTION;

    return (
      <Table.Cell {...props}>
        {status}
      </Table.Cell>
    )
  }

  if (column.name === 'delivery_method' && !data.noDeliveries) {
    const isDeliveryMethodChangeStage = [PLATE_TYPE.FREIGHT, PLATE_TYPE.PACKING].includes(actionType?.toUpperCase());

    if (isDeliveryMethodChangeStage) {
      const staticDeliveryMethod = data.sellerTeams.staticPackingMethodKey;
      const deliveryMethod = data.delivery_method || data.packingMethod;
      return (
        <Table.Cell {...props} onClick={(evt: React.MouseEvent<HTMLDivElement>) => evt.stopPropagation()}>
          <InlineDictionarySelect
            onChange={submitDeliveryMethod}
            dictionary={deliveryMethods}
            value={staticDeliveryMethod || deliveryMethod}
            confirmKey="packing method"
            disabled={!!staticDeliveryMethod}
            small
          />
        </Table.Cell>
      );
    }

    const deliveryMethodText = deliveryMethodDictionary[data.delivery_method] || "<unset>";
    return (
      <Table.Cell {...props}>
        {deliveryMethodText}
      </Table.Cell>
    )
  }

  if (column.name === 'fault_at') {
    return (
      <Table.Cell {...props}>
        <FaultDate fault_at={data.fault_at} />
      </Table.Cell>
    );
  }

  if (column.name === 'created_at') {
    return (
      <Table.Cell {...props}>
        <FaultDate fault_at={data.created_at} />
      </Table.Cell>
    );
  }

  if (column.name === 'user_picker') {
    return (
      <Table.Cell {...props}>
        <DataTableFaultByCell faultId={data.fault_id} faultBy={data.fault_by} />
      </Table.Cell>
    );
  }

  if (column.name === 'cost') {
    return (
      <Table.Cell {...props}>
        <DataTableCostCell faultId={data.fault_id} cost={data.cost} />
      </Table.Cell>
    );
  }

  if (column.name === 'user_id') {
    return (
      <Table.Cell {...props}>
        <DataTableUserCell user_id={data.user_id} />
      </Table.Cell>
    );
  }

  if (column.name === 'installation_date') {
    return (
      <Table.Cell {...props}>
        {data.installation_date && formatToDate(data.installation_date)}
      </Table.Cell>
    );
  }

  if (column.name === 'is_invoiced') {
    return (
      <Table.Cell {...props}>
        <DataTableInvoicedCell referenceNumber={data.reference_number} isInvoiced={data.is_invoiced} />
      </Table.Cell>
    );
  }

  if (column.name === 'action') {
    return (
      <Table.Cell align="left" {...props}>
        {getActionType({ actionType, checkpoints, data })}
      </Table.Cell>
    );
  }

  if (column.name === 'info_box') {
    return (
      <Table.Cell align="right" {...props}>
        <Button variant="contained">Info</Button>
      </Table.Cell>
    );
  }

  if (column.name === 'image_table') {
    return (
      <Table.Cell align="right" {...props}>
        <DataTableDeviationImages plate={data} />
      </Table.Cell>
    );
  }

  return <Table.Cell {...props} style={{
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    display: data.isExtraInfo && isTablet ? "none" : ""
  }} />;
};

