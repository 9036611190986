import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { useIsAdmin } from '../withIsAdmin';
import { useUserInput } from '../../hooks/useUserInput';
import { resetFreightConfirmDate } from '../../redux/actions/ordersActions';
import { MyDialog } from '../../components/Dialog/MyDialog';
import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { ErrorMessage } from '../../components/Common/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { WarningInfo } from '../../components/WarningInfo';

type FreightRestoreDateType = {
  orderId: number,
  referenceNumber: string,
  disabled: boolean,
  callback: () => void
}

export function FreightRestoreDate({ orderId, referenceNumber, disabled, callback }: FreightRestoreDateType) {
  const isAdmin = useIsAdmin();

  const dispatch = useDispatch();
  const { onUserChange, user } = useUserInput();
  const { error, handleError, isUserError, resetError } = useErrorHandler();

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (!isAdmin) {
    return null;
  }

  const onSubmit = async () => {
    try {
      await resetFreightConfirmDate(orderId);
      callback();
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  };

  return (
    <div style={{ marginLeft: 8, marginRight: 8 }}>
      <MyDialog
        dialogTitle={`Do you want to restore pickup date on order ${referenceNumber}?`}
        size="medium"
        color="secondary"
        variant="text"
        buttonLabel="Restore Date"
        modalSize="xs"
        onSubmit={onSubmit}
        onSubmitDisabled={disabled}
        onSubmitClose
        submitButtonColor="secondary"
      >
        <div style={{ flex: 1 }}>
          {disabled && <WarningInfo>Installation date need to be restored before freight-date can be restored</WarningInfo>}
          <Typography variant="subtitle2">
            Are you sure you want to restore date from order app?
          </Typography>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
          <ErrorMessage message={error} isVisible={!!error} />
        </div>
      </MyDialog>
    </div>
  );
}
