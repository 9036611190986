import { Dispatch } from 'redux';

import { APIClient } from '../../services/apiService';

import { FileActionTypes } from './FileTypes';
import * as FileTypes from './FileTypes';

const fetchFilesByPlate = (id: number, files: FileType[]): FileActionTypes => ({
  type: FileTypes.FETCH_FILES_BY_PLATE,
  id,
  files
});
const fetchFilesByOrder = (id: number, files: FileType[]): FileActionTypes => ({
  type: FileTypes.FETCH_FILES_BY_ORDER,
  id,
  files
});
export const fetchFilesForRecord = (orderId: number, plateId?: number) => async (dispatch: Dispatch) => {
  if (plateId) {
    const response = await APIClient.get(`files/order/${orderId}/plate/${plateId}`);
    dispatch(fetchFilesByPlate(plateId, response.data));
    dispatch(fetchFilesByOrder(orderId, response.data));
  } else {
    const response = await APIClient.get(`files/order/${orderId}`);
    dispatch(fetchFilesByOrder(orderId, response.data));
  }
};

export const fetchCurrentFilesForRecord = (orderId: number, stage: string) => async (dispatch: Dispatch) => {
  const response = await APIClient.get(`files/order/${orderId}/stage/${stage}`);
  dispatch(fetchFilesByOrder(orderId, response.data));
};

export const fetchFileBySocket = (file: FileType): FileActionTypes => ({
  type: FileTypes.FETCH_FILE_BY_SOCKET,
  file,
  order_id: file.order_id,
  plate_id: file.plate_id
});


export const deleteFileBySocket = (file: FileType): FileActionTypes => ({
  type: FileTypes.DELETE_FILE_BY_SOCKET,
  file,
  order_id: file.order_id,
  plate_id: file.plate_id
});



