import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import { LinkToList } from '../LinkToList';
import { PLATE_TYPE } from '../../shared/constants';
import { selectPlateEntities } from '../../selectors/PlateSelectors';
import { Theme } from '@material-ui/core';
import { FilterOrders } from '../../components/Filters/FilterOrders';

const useStyles = makeStyles((theme: Theme) => ({
  singleRow: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(9),
    }
  },
  doubleRow: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(1),
    }
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '28% 36% 36%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  narrowPanel: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(16),
    }
  }
}));

export const Navigation = () => {
  const styles = useStyles();

  const [parentWidth, setParentWidth] = useState<number>(window.innerWidth);
  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth || window.innerWidth); // Now this works correctly
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isNarrowPanel = parentWidth < 600;

  const orders: Order[] = useSelector((state: GlobalStoreType) => {
    const { list, entities, newOrdersInfoEntities } = state.orders;
    
    return list.map(element => {
      const order = { ...entities[element] };
      order.sellerTeams = newOrdersInfoEntities[order.reference_number as any]?.sellerTeams;
      return order;
    });
  });

  const isAdmin = useSelector((state: GlobalStoreType) => state.account.isAdmin);
  const isMonter = useSelector((state: GlobalStoreType) => state.account.isMonter);

  const sellerTeams = useSelector((state: GlobalStoreType) => state.sellerTeams);
  const filteredOrders = (sellerTeams.selMeasuringTeams.length > 0 || sellerTeams.selInstallationTeams.length > 0)
    ? orders.filter((order: Order) => (
        order.sellerTeams && (
          sellerTeams.selMeasuringTeams.includes(order.sellerTeams.measuringId) ||
          sellerTeams.selInstallationTeams.includes(order.sellerTeams.installationId)
        )
      ))
    : orders;

  const newOrders = filteredOrders.filter(element => !element.offer_version && !element.cancel_date);
  const existingOrders = filteredOrders.filter(element => element.offer_version);

  const plates: Plate[] = useSelector(selectPlateEntities);

  const toMeasure: Order[] = newOrders.filter(order => !order.measure_date && !order.approve_date);
  const toApprove: Order[] = newOrders.filter(order => order.measure_date && !order.approve_date);

  const toPrepare: Order[] = newOrders.filter(order => !order.has_plates && order.approve_date && order.is_freight === null);

  const getLength = (stage: string) => plates.filter(element => element.current_stage === stage).length;

  const getOrderLength = (stage: string) => [...new Set(
    plates
      .filter(element => element.current_stage === stage)
      .map(plate => plate.order_id)
  )].length;

  const toFreight: Order[] = existingOrders.filter(order => order.is_freight);
  const toStorage = existingOrders.filter(order => order.is_storage);
  const toInstallation = existingOrders.filter(order => order.is_installation);

  return (
    <div ref={parentRef}>
      <div className={`${isNarrowPanel ? styles.doubleRow : styles.singleRow}`}>
        {(isAdmin || isMonter) && <FilterOrders />}
      </div>
      <div className={`${styles.root} ${isNarrowPanel ? styles.narrowPanel : ''}`}>
        <LinkToList link="/measurements" label="MEASUREMENTS" count={toMeasure.length} mode="mergedRow" />
        <LinkToList link="/internal_control" label="INTERNAL CONTROL" count={toApprove.length} mode="mergedCol" />
        {!isMonter &&
          <React.Fragment>
            <LinkToList link="/prepare" label="PREPARE" count={toPrepare.length} />
            <LinkToList link="/find" label="FIND" count={`${getOrderLength(PLATE_TYPE.FIND)} (${getLength(PLATE_TYPE.FIND)})`} />
          </React.Fragment>
        }
        <LinkToList link="/freight" label="FREIGHT" count={toFreight.length} mode="mergedRow" toEnd />
        {!isMonter &&
          <React.Fragment>
            <LinkToList link="/cut" label="CUT" count={`${getOrderLength(PLATE_TYPE.CUT)} (${getLength(PLATE_TYPE.CUT)})`} />
            <LinkToList link="/edgepolish" label="EDGE POLISH" count={getLength(PLATE_TYPE.EDGE_POLISH)} />
            <LinkToList link="/holes-cnc" label="HOLES/CNC" count={getLength(PLATE_TYPE.HOLES)} />
            <LinkToList link="/manual-finish" label="MANUAL FINISH" count={getLength(PLATE_TYPE.MANUAL_FINISH)} />
          </React.Fragment>
        }
        <LinkToList link="/installation" label="INSTALLATION" count={toInstallation.length} mode="mergedRow" toEnd />
        {!isMonter &&
          <React.Fragment>
            <LinkToList link="/install-sink" label="INSTALL SINK" count={getLength(PLATE_TYPE.INSTALL_SINK)} />
            <LinkToList link="/endcontrol" label="END CONTROL" count={getLength(PLATE_TYPE.END_CONTROL)} />
          </React.Fragment>
        }
        <LinkToList link="/packing" label="PACKING" count={`${getOrderLength(PLATE_TYPE.PACKING)} (${getLength(PLATE_TYPE.PACKING)})`} />
        <LinkToList link="/storage" label="STORAGE" count={toStorage.length} />
      </div>
    </div>
  );
};
