import * as React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from 'material-ui-pickers';
import { Theme, Typography } from '@material-ui/core';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from '../../components/Dialog/MyDialog';

import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { estimateOrder } from '../../redux/actions/ordersActions';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import { formatToDate, formatToSaveDate } from '../../services/DateFormatter';

type MeasurementsEstimateDialogType = {
  orderId: number,
  date: Date,
  isEstimated: boolean,
  buttonLabel: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  }
}));

export function MeasurementsEstimateDialog({ orderId, date, isEstimated, buttonLabel }: MeasurementsEstimateDialogType) {
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const [_date, changeDate] = React.useState(date || new Date());

  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeDate = async (newDate: Date) => changeDate(newDate);

  React.useEffect(() => {
    resetError();
  }, [user]);


  if (!(isAdmin || isMonter)) {
    return null;
  }

  function resetLocalState() {
    resetUser();
  }

  async function submitEstimateOrder() {
    try {
      const date = formatToSaveDate(_date);
      await dispatch(estimateOrder(orderId, {
        user_id: user,
        date: date
      }));
      resetLocalState();
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const formattedDate = date ? formatToDate(date) : buttonLabel;

  return (
    <MyDialog
      buttonLabel={formattedDate}
      dialogTitle="Estimate"
      color={isEstimated ? 'primary' : 'secondary'}
      variant={isEstimated ? 'raised' : 'outlined'}
      onSubmit={submitEstimateOrder}
      modalSize="xs"
    >
      <div className={classes.root}>
        <div className={classes.row}>
          <Typography variant="subtitle2">Estimate date of measurements</Typography>
        </div>
        <div className={classes.row}>
          <DatePicker
            fullWidth
            animateYearScrolling
            variant="outlined"
            value={_date}
            onChange={onChangeDate}
            disablePast
            format="dd-MM-yyyy"
            minDateMessage=""
            clearable
          />
        </div>
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}