import * as OrderTypes from './OrderTypes';
import { OrderActionTypes } from './OrderTypes';
import * as CommentTypes from '../comments/CommentTypes';
import * as FileTypes from '../files/FileTypes';
import * as HistoryTypes from '../history/HistoryTypes';
import * as MaterialTypes from '../materials/MaterialTypes';

const initialState: OrderStoreType = {
  list: [],
  entities: {},
  newOrdersInfoEntities: {},
  isLoading: true,
};


export function orderReducer(state = initialState, action: OrderActionTypes): OrderStoreType {
  switch (action.type) {
    case OrderTypes.FETCH_NEW_ORDERS:
    case OrderTypes.FETCH_EXISTING_ORDERS:
      return {
        ...state,
        entities: { ...state.entities, ...action.orders.entities.order },
        list: [...new Set([...state.list, ...action.orders.result])],
        isLoading: false,
      };

    case OrderTypes.FETCH_ORDER_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.order.id]: {
            ...state.entities[action.order.id],
            ...action.order
          }
        },
        list: [...new Set([...state.list, action.order.id])]
      };

    case OrderTypes.FETCH_NEW_ORDERS_INFO:
      return {
        ...state,
        newOrdersInfoEntities: { ...state.newOrdersInfoEntities, ...action.orders.entities.order },
      };

    case OrderTypes.FETCH_ORDER_INFO_BY_SOCKET:
      if (!action.order.timeSinceReady) {
        action.order.timeSinceReady = state.newOrdersInfoEntities[action.order.offerId]?.timeSinceReady;
      }

      return {
        ...state,
        newOrdersInfoEntities: {
          ...state.newOrdersInfoEntities,
          [action.order.offerId]: action.order
        }
      };

    case OrderTypes.DELETE_ORDER_BY_SOCKET:
      return {
        ...state,
        list: state.list.filter(element => element != action.id),
        entities: {
          ...state.entities
        },
      }

    case OrderTypes.LOG_HISTORY_UPDATE:
      return {
        ...state,
        ...action.payload.isOrderInfoEntity ? {
          entities: {
            ...state.entities,
            [action.payload.id]: {
              ...state.entities[action.payload.id],
              stepStatuses: {
                ...state.entities[action.payload.id].stepStatuses,
                [action.payload.key as any]: action.payload.value
              }
            }
          }
        } : {
          newOrdersInfoEntities: {
            ...state.newOrdersInfoEntities,
            [action.payload.id]: {
              ...state.newOrdersInfoEntities[action.payload.id],
              stepStatuses: {
                ...state.newOrdersInfoEntities[action.payload.id].stepStatuses,
                [action.payload.key as any]: action.payload.value
              }
            }
          }
        }
      };

    case OrderTypes.DELETE_LOG_HISTORY_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            stepStatuses: {},
            files: []
          },
        },
      }

    case MaterialTypes.FETCH_MATERIALS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...state.list.map(key => ({
            [key]: {
              ...state.entities[key],
              material: action.materials.find(element => element.order_id === key) || {
                name: '',
                thickness: '',
                type: '',
                need_impregnation: false
              }
            }
          })).reduce((acc, value) => ({ ...acc, ...value }), {})
        }
      };

    case CommentTypes.FETCH_COMMENTS_BY_ORDER:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            comments: action.comments
          }
        }
      };

    case CommentTypes.FETCH_COMMENT_BY_ID:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.order_id]: {
            ...state.entities[action.order_id],
            comments: state.entities[action.order_id].comments ? [action.comment, ...state.entities[action.order_id].comments.filter(e => e.id !== action.comment.id)] : [action.comment]
          }
        }
      };

    case CommentTypes.DELETE_COMMENT_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.order_id]: {
            ...state.entities[action.order_id],
            comments: state.entities[action.order_id].comments ? [...state.entities[action.order_id].comments.filter(e => e.id !== action.id)] : []
          }
        }
      };

    case FileTypes.FETCH_FILES_BY_ORDER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            files: action.files
          }
        }
      };
    }

    case FileTypes.FETCH_FILE_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.order_id]: {
            ...state.entities[action.order_id],
            files: state.entities[action.order_id].files ? [...state.entities[action.order_id].files.filter(e => e.id !== action.file.id), action.file] : [action.file]
          }
        }
      };

    case FileTypes.DELETE_FILE_BY_SOCKET:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.order_id]: {
            ...state.entities[action.order_id],
            files: state.entities[action.order_id].files ? [...state.entities[action.order_id].files.filter(e => e.id !== action.file.id)] : []
          }
        }
      };

    case HistoryTypes.FETCH_HISTORY_BY_ORDER:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            actions: action.history
          }
        }
      };

    default:
      return state;
  }
}
