import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Paper,
  Theme,
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

type EnforceRefreshType = {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  syncingIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
  },
  progressSpinner: {
    marginRight: theme.spacing(2),
  }
}));

export const EnforceRefresh: React.FunctionComponent<EnforceRefreshType> = ({ children }) => {
  const classes = useStyles();
  const onClick = useCallback(() => {
    window.location.reload();
    return false;
  }, []);

  const requireRefresh = useSelector((state: GlobalStoreType) => state.configuration.requireRefresh);
  const isSyncing = useSelector((state: GlobalStoreType) => state.configuration.isSyncing);
  
  if (requireRefresh) {
    return (
      <>
        {children}
        <Dialog open={true}>
          <DialogTitle id="form-dialog-title">
            {isSyncing ? 'Connection Restored' : 'Connection Lost'}
          </DialogTitle>
          <DialogContent className={classes.root}>
            {isSyncing ? (
              <>
                <DialogContentText>
                  Connection has been restored. Your data is being synchronized...
                </DialogContentText>
                
                <Box className={classes.syncingIndicator}>
                  <CircularProgress size={24} className={classes.progressSpinner} />
                  <Typography variant="body1">Syncing application data</Typography>
                </Box>
              </>
            ) : (
              <>
                <DialogContentText>
                  This dialog will disappear when connection will reestablished.
                </DialogContentText>
                <DialogContentText>
                  You can also click below button to force refresh but your current unsaved changes will be gone.
                </DialogContentText>
              </>
            )}
          </DialogContent>
          {!isSyncing && (
            <DialogActions>
              <Button onClick={onClick} color="secondary" variant="contained">
                Refresh
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </>
    );
  }

  return <>{children}</>;
};
