import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { 
  FormControl, InputLabel, MenuItem, OutlinedInput, Select, Theme
} from '@material-ui/core';
import { PreventPropagation } from "../../components/PreventPropagation";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { ConfirmChangeDialog } from "./ConfirmChangeDialog";

type InlineDictionarySelectType = {
  label?: string,
  value: string,
  onChange: (evt: any) => void,
  dictionary: DictionaryType[],
  confirmKey?: string,
  disabled?: boolean,
  small?: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  smallInput: { padding: theme.spacing(0.5) },
  modal: {
    margin: 0
  }
}));

export function InlineDictionarySelect({ label, dictionary, value, onChange, confirmKey, disabled, small }: InlineDictionarySelectType) {
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);

  const showConfirmation = confirmKey && value;

  React.useEffect(() => {
    setLabelWidth((ReactDOM.findDOMNode(labelRef.current) as HTMLElement)!.offsetWidth);
  }, []);

  const inputClasses = classnames({
    [classes.smallInput]: small
  });

  const [isOpenConfirmation, setOpenConfirmation] = React.useState(false);
  const [tempValue, setTempValue] = React.useState(null);

  const onChangeHandler = (e) => {
    const selectedValue = e.target.value;

    if (showConfirmation) {
      setTempValue(selectedValue);
      setOpenConfirmation(true);
    } else {
      onChange(selectedValue);
    }
  };
  
  const onConfirmChange = () => {
    setOpenConfirmation(false);
    onChange(tempValue);
  };

  const onCancel = () => {
    setTempValue(null);
    setOpenConfirmation(false);
  };

  const dictionaryText = Object.fromEntries(dictionary.map(item => [item.value, item.text]));

  const hasError = !value;
  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl} error={hasError}>
      <InputLabel ref={labelRef} htmlFor="dictionary-select-input" error={hasError}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChangeHandler}
        variant="outlined"
        fullWidth
        displayEmpty
        disabled={disabled}
        error={hasError}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="dictionary-select"
            id="dictionary-select-input"
            classes={{ input: inputClasses }}
            error={hasError}
          />
        }
      >
        {dictionary.map(({ text, value }: DictionaryType) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>

      {showConfirmation && (
        <PreventPropagation>
          <ConfirmChangeDialog
            open={isOpenConfirmation}
            onCancel={onCancel}
            onConfirm={onConfirmChange}
            confirmKey={confirmKey}
            fromValue={dictionaryText[value]}
            toValue={dictionaryText[tempValue]}
          />
        </PreventPropagation>
      )}
    </FormControl>
  );
}
