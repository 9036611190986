import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import { SocketListener } from './containers/SocketListener';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore';
import { Router } from './AppRoutes';

const storeInstance = configureStore();

export { storeInstance };

const redirectToHttps = () => {
  const isDev = process.env.NODE_ENV === 'development';
  if (isDev) {
    return;
  }

  if (window.location.protocol !== 'https:') {
    window.location.protocol = 'https:';
  }
};

redirectToHttps();

const render = (Component) => ReactDOM.render(
  <Provider store={storeInstance.store}>
    <PersistGate loading={null} persistor={storeInstance.persistor}>
      <BrowserRouter>
        <SocketListener>
          <Component />
        </SocketListener>
      </BrowserRouter>
    </PersistGate>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

render(Router);

if (module.hot) {
  module.hot.accept('./AppRoutes', () => {
    const NextApp = require('./AppRoutes').Router;
    render(NextApp);
  });
}

