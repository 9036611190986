import * as React from "react";
import { useSelector } from "react-redux";

import { selectCheckpoints, selectDeliveryMethods } from "../../redux/selectors";
import { selectPlateFullData } from '../../selectors/PlateSelectors';
import { MetaDataGroupingTable } from "../MetaDataTable/MetaDataGroupingTable";
import { PLATE_TYPE } from "../../shared/constants";

export function PackingDataTable() {
  const list = useSelector(selectPlateFullData());
  const deliveryMethodsDictionary = useSelector(selectDeliveryMethods);

  const getOrderIdsWithPacking = list
    .filter((element: Plate) => (element.current_stage === PLATE_TYPE.PACKING))
    .map((element: Plate) => element.order_id);

  const data = list
    .filter((element: Plate) => getOrderIdsWithPacking.includes(element.order_id));

  const checkpoints: CheckpointType[] = useSelector(selectCheckpoints("packingCheckpoints"));

  return (
    <MetaDataGroupingTable
      title="Packing"
      actionType="packing"
      rows={data}
      checkpoints={checkpoints}
      deliveryMethods={deliveryMethodsDictionary}
    />
  );
}
