import * as React from 'react'
import { Checkbox, FormControlLabel, Theme } from "@material-ui/core";
import { FileDropzone } from "../Common/FileDropzone";
import { FileUploadTable } from "../FileUploadTable/FileUploadTable";
import { FileUploader } from "../FileUploader";
import { useFileUploader } from "../../hooks/useFileUploader";
import { makeStyles } from "@material-ui/styles";

type Props = {
  files: Array<FileType>;
  stepId?: string;
  fileType: string;
  orderType: string,
  orderId: number;
  userId: number;
  referenceNumber: string;
  label?: string;
  currentValue: boolean | any;
  processNextStage: (value: boolean) => void;
  noRequiredLabel?: string;
}

const useClasses = makeStyles((theme: Theme) => ({
  confirm: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: theme.spacing(1),
  },
  filesGallery: {
    display: "flex",
    overflowY: 'auto',
    justifyContent: 'space-between'
  },
  filesTable: {
    flex: '0 0 calc(50% - 2.5%)'
  },
}));


export const WizardStepStageFile: React.FC<Props> = ({ files, stepId, fileType, orderType, orderId, userId, referenceNumber, label, currentValue, processNextStage, noRequiredLabel }) => {
  const classes = useClasses();

  const { filesState, handleSetFilesAndSend, resetState } = useFileUploader({
    prependName: `${orderType}-ORDER-${referenceNumber}`,
    type: fileType,
    orderId: orderId,
    userId: userId,
    isPlate: false
  });

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    processNextStage(!event.target.checked);
  };

  const onUploadFile = async (evt: HTMLInputEvent) => {
    try {
      await handleSetFilesAndSend(evt);
      resetState();
    } catch (err) {
    }
  };

  const orderTypeFiles = files.filter(e => e.key.includes(orderType));
  const stageFiles = orderTypeFiles.filter(e => e.file_type === fileType);
  
  const stageFilesA = stageFiles.filter((_file, index) => index % 2 === 0);
  const stageFilesB = stageFiles.filter((_file, index) => index % 2 === 1);
  
  const filesStateA = filesState.filter((_file, index) => index % 2 === 0);
  const filesStateB = filesState.filter((_file, index) => index % 2 === 1);
  
  const filesA = [...stageFilesA, ...filesStateA] as (FileUploadServer & FileType)[];
  const filesB = [...stageFilesB, ...filesStateB] as (FileUploadServer & FileType)[];

  const fileUploaderLabel = label || `Upload ${stepId || 'picture'}`;
  return (
      <FileDropzone onUpload={onUploadFile}>
        <div className={classes.filesGallery}>
          <div className={classes.filesTable}>
            <FileUploadTable
              files={filesA} />
          </div>
          <div className={classes.filesTable}>
            <FileUploadTable
              files={filesB} />
          </div>
        </div>
        <br />
        <div className={classes.confirm}>
          <div>
            {noRequiredLabel && (
              <FormControlLabel control={
                  <Checkbox color="primary" 
                    checked={currentValue}
                    onChange={onChangeCheckbox}
                  />
                }
                label={noRequiredLabel}
              />
            )}
          </div>
          <FileUploader label={fileUploaderLabel} addFiles={onUploadFile} />
        </div>
      </FileDropzone>
  )
}
