import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// @ts-ignore
import io from 'socket.io-client';
import { getForEnv } from '../shared/getForEnv';
import { abandonRefresh, enforceRefresh, setSyncing } from '../redux/AppConfigurationRedux';
import {
  fetchCommentById,
  fetchImportantCommentById,
  deleteCommentBySocket,
  fetchComments
} from '../redux/comments/CommentActions';
import { deleteFileBySocket, fetchFileBySocket } from '../redux/files/FileActions';
import {
  deleteOrderBySocket,
  fetchOrderBySocket,
  fetchOrderInfoBySocket,
  deleteLogHistoryBySocket,
  fetchOrders
} from '../redux/orders/OrderActions';
import { fetchFaultBySocket, fetchPlateBySocket, fetchFaults, fetchPlates } from '../redux/plates/PlateActions';
import { fetchPlateTypes, fetchPlateTypesBySocket } from '../redux/plateTypes/PlateTypeActions';
import { fetchNotificationBySocket } from '../redux/notifications/NotificationActions';
import { fetchMaterials } from '../redux/materials/MaterialActions';

const url = window.location.protocol + '//' + window.location.hostname + ':' + getForEnv(3001, 443);
const socket = io(url);

interface SocketListenerProps extends RouteComponentProps {
  children: React.ReactNode;
}

const SocketListenerComponent: React.FC<SocketListenerProps> = ({ children, location }) => {
  const dispatch = useDispatch();
  const isCompletedRoute = location.pathname === '/completed';

  // Function to refetch dynamic data on reconnection
  const refetchDynamicData = async () => {
    // Skip refetching on completed route
    if (isCompletedRoute) {
      return;
    }

    try {
      console.log('Syncing data after reconnection...');

      // Show the dialog and set syncing flag
      dispatch(enforceRefresh());
      dispatch(setSyncing(true));

      await Promise.all([dispatch(fetchOrders()), dispatch(fetchPlates())]);
      await Promise.all([dispatch(fetchFaults()), dispatch(fetchMaterials()), dispatch(fetchComments())]);

      dispatch(setSyncing(false));
      dispatch(abandonRefresh());
      console.log('Sync complete');
    } catch (error) {
      console.error('Error syncing data:', error);
      // Keep the dialog open but turn off syncing indicator if there was an error
      dispatch(setSyncing(false));
    }
  };

  React.useEffect(() => {
    // Skip socket event listeners on completed route
    if (isCompletedRoute) {
      return;
    }

    socket.on('refresh-file', (data: FileType) => {
      dispatch(fetchFileBySocket(data));
    });
    socket.on('delete-file', (data: FileType) => {
      dispatch(deleteFileBySocket(data));
    });
    socket.on('refresh-comment', (data: any) => {
      dispatch(fetchCommentById(data));
    });
    socket.on('refresh-important-comment', (data: any) => {
      dispatch(fetchImportantCommentById(data));
      dispatch(fetchCommentById(data));
    });
    socket.on('delete-comment', (data: CommentType) => {
      dispatch(deleteCommentBySocket(data))
    });

    socket.on('refresh-order', (data: Order) => dispatch(fetchOrderBySocket(data)));
    socket.on('refresh-order-info', (data: OrderInfo) => dispatch(fetchOrderInfoBySocket(data)));

    socket.on('delete-order', (id: number) => dispatch(deleteOrderBySocket(id)));

    socket.on('refresh-plate', (data: Plate) => dispatch(fetchPlateBySocket(data)));
    socket.on('refresh-plates', (data: Plate[]) => data.map((plate: Plate) => dispatch(fetchPlateBySocket(plate))));
    socket.on('refresh-plate-type', (data: PlateType) => dispatch(fetchPlateTypesBySocket(data)));
    socket.on('refresh-plates-types', (data: PlateType[]) => data.map((plate: PlateType) => dispatch(fetchPlateTypesBySocket(plate))));

    socket.on('refresh-fault', (data: Fault) => dispatch(fetchFaultBySocket(data)));

    socket.on('refresh-notification', (data: NotificationType) => dispatch(fetchNotificationBySocket(data)));

    socket.on('delete-log-history', (id: number) => dispatch(deleteLogHistoryBySocket(id)));

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Listen for reconnect event and start data sync
    socket.on('reconnect', () => {
      console.log('Socket reconnected, syncing data...');
      refetchDynamicData();
    });

    socket.on('reconnect_attempt', (attemptNumber: number) => {
      console.log({ attemptNumber })
      if (attemptNumber > 9) {
        dispatch(enforceRefresh());
      }
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      socket.off('refresh-file');
      socket.off('delete-file');
      socket.off('refresh-comment');
      socket.off('refresh-important-comment');
      socket.off('delete-comment');
      socket.off('refresh-order');
      socket.off('refresh-order-info');
      socket.off('delete-order');
      socket.off('refresh-plate');
      socket.off('refresh-plates');
      socket.off('refresh-plate-type');
      socket.off('refresh-plates-types');
      socket.off('refresh-fault');
      socket.off('refresh-notification');
      socket.off('delete-log-history');
      socket.off('disconnect');
      socket.off('reconnect');
      socket.off('reconnect_attempt');
      socket.off('error');
    };
  }, [isCompletedRoute]); // Add isCompletedRoute to dependencies

  return <>{children}</>;
}

export const SocketListener = withRouter(SocketListenerComponent);
