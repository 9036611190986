import * as React from "react";
import {
  Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
  Button, Theme, Box
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

type ConfirmChangeDialogType = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmKey: string;
  fromValue: string;
  toValue: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  paper: {
    margin: 0
  },
  comparison: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '80px auto',
    gap: theme.spacing(1)
  }
}));

export const ConfirmChangeDialog: React.FC<ConfirmChangeDialogType> = ({
  open,
  onCancel,
  onConfirm,
  confirmKey,
  fromValue,
  toValue
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onCancel}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="form-dialog-title">
        Confirm change?
      </DialogTitle>
      <DialogContent className={classes.root}>
        <DialogContentText>
          Are you sure you want to change {confirmKey}?
        </DialogContentText>
        <Box className={classes.comparison}>
          <DialogContentText variant="body2">From:</DialogContentText>
          <DialogContentText><strong>{fromValue}</strong></DialogContentText>
          <DialogContentText variant="body2">To:</DialogContentText>
          <DialogContentText><strong>{toValue}</strong></DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
