import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LockOrientationContext } from '../../components/LockOrientation';
import { useSelector } from 'react-redux';

import {
  IntegratedFiltering,
  SearchState,
  PagingState,
  IntegratedPaging,
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  SearchPanel,
  TableHeaderRow,
  TableRowDetail,
  Table,
  Toolbar,
  PagingPanel,
  TableTreeColumn
} from '@devexpress/dx-react-grid-material-ui';
import { CircularProgress, Paper, Theme } from '@material-ui/core';

import { CANCEL_TYPE, ORDER_TYPE, PLATE_TYPE } from '../../shared/constants';
import { Cell, OrderReferenceNumberCell } from '../../containers/MetaDataTable/MetaDataTableCell';
import { MetaDataTableRow } from '../../containers/MetaDataTable/MetaDataTableRow';
import { selectPlateEntities } from '../../selectors/PlateSelectors';
import { selectDisplayWithInfoRow } from '../../redux/selectors';
import { getContentComponent } from '../../containers/MetaDataTable/MetaDataContentComponentProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  loader: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const columns = [
  { name: 'order_reference_number', title: 'Order Id' },
  { name: 'reference_number', title: 'Plate Id' },
  { name: 'current_stage', title: 'Current Stage' }
];

const desktopColumnsWidth = [
  { columnName: 'order_reference_number', width: 140 },
  { columnName: 'reference_number', width: 100 },
  { columnName: 'current_stage' },
];

const getStage = (order: Partial<Order>) => {
  if (!order.measure_date) {
    return 'MEASUREMENTS';
  }

  if (!order.approve_date) {
    return 'INTERNAL_CONTROL';
  }

  return 'PREPARE';
};

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getUnique = (arr: any[], comp: string) => arr
  .map(e => e[comp])
  .map((e, i, final) => final.indexOf(e) === i && i)
  .filter((e: any) => arr[e])
  .map((e: any) => arr[e]);

const getUniqueGroups = (rows: Partial<Order>[]) => {
  const groupedOrders = groupBy(rows, 'rootId');
  const groups = Object.keys(groupedOrders).map(key => ({
    count: groupedOrders[key].length,
    key: key
  }));

  const uniqueRows = getUnique(rows, 'rootId')
    .map(elem => ({
      ...elem, ...groups
        .find(c => c.key === `${elem.id}_${elem.current_stage}`)
    }));

  return uniqueRows.map((element: any) => ({
    ...element,
    parentId: null,
    rootId: `${element.order_reference_number}_${element.current_stage}_${element.reference_number}`,
    comments: element.comments
  }));
};

const getOrderRows = (rows: any[], type: string) => {
  const filteredRows = rows.filter(row => row[`is${type}`]);

  const transformedRows = filteredRows.map(({ current_stage, ...rest }) => ({
    current_stage: type.toUpperCase(),
    ...rest,
  }));

  const uniqueRows = getUnique(transformedRows, 'order_id');

  return uniqueRows.map(({ id, order_id, reference_number, ...rest }) => ({
    id: order_id,
    reference_number: '',
    ...rest,
  }));
}

const createGroupInfoObjects = (elem: any) => ({
  isExtraInfo: true,
  parentId: `${elem.order_reference_number}_${elem.current_stage}_${elem?.reference_number}`,
  deliveryAddress: elem.deliveryAddress,
  materialStatus: elem.materialStatus,
  cutouts: elem.cutouts,
  craneRequired: elem.history && elem.history['Crane required'],
  extraHelpForLift: elem.history && elem.history['Extra help required to lift'],
  estimatedInstallationTime: elem.history && elem.history['Estimated Installation Time']
});

const getChildRows = (row: any, rootRows: any) => {
  const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.rootId : null));
  return childRows.length ? childRows : null;
};

const ReferenceNumberFormatter = (props: any) => (
  <OrderReferenceNumberCell {...props.row} />
);

const ReferenceNumberFormatterProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={ReferenceNumberFormatter}
    {...props}
  />
);

export function SearchOrder() {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const isDesktopMedia = useMediaQuery(theme.breakpoints.up('sm'));
  const { isDesktopDevice, isMediumDevice } = React.useContext(LockOrientationContext);

  let isDesktop = isDesktopMedia;

  if (isDesktopDevice) {
    isDesktop = true;
  }

  if (isMediumDevice) {
    isDesktop = false;
  }

  const isLoading = useSelector((state: GlobalStoreType) => state.orders.isLoading)
  const newOrderRows: Partial<Plate | Order>[] = useSelector((state: GlobalStoreType) => selectDisplayWithInfoRow(order => !order.cancel_date && !order.offer_version)(state)
    .map(order => ({
      ...order,
      reference_number: '',
      deliveryAddress: state.orders.newOrdersInfoEntities[+order.reference_number]?.deliveryAddress,
      order_reference_number: order.reference_number,
      current_stage: getStage(order)
    }))
    .sort((a, b) => {
      const textA = a.current_stage;
      const textB = b.current_stage;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }));

  const plateRows: Plate[] = useSelector((state: GlobalStoreType) => selectPlateEntities(state)
    .filter((element: Plate) => element.current_stage !== PLATE_TYPE.COMPLETED && element.current_stage !== CANCEL_TYPE)
    .map((element: Plate) => {
      const order = state.orders.entities[element.order_id] || {};
      return {
        ...element,
        order_reference_number: order.reference_number,
        deliveryAddress: order.deliveryAddress,
        materialStatus: order.materialStatus,
        cutouts: order.cutouts,
        history: order.history,
        isFreight: order.is_freight,
        isStorage: order.is_storage,
        isInstallation: order.is_installation
      }
    }));
  const storagePlateRows = plateRows
    .filter(row => row.isStorage)
    .map(row => {
      const status = (row.current_stage === PLATE_TYPE.INSTALLATION && row.has_freight) ? ORDER_TYPE.INSTALLATION : ORDER_TYPE.PRODUCTION;
      return {
        ...row,
        current_stage: 'STORAGE - ' + status,
        reference_number: row.reference_number
      }
    });
  const installationOrderRows = getOrderRows(plateRows, 'Installation');
  const freightOrderRows = getOrderRows(plateRows, 'Freight');

  const orderRows = [...newOrderRows, ...freightOrderRows, ...installationOrderRows, ...storagePlateRows];
  const orderData = orderRows.map((row: any) => ({
    ...row,
    rootId: `${row.id}_${row.current_stage}`
  }));

  const plateData = plateRows.map(row => ({
    ...row,
    rootId: `${row.order_id}_${row.current_stage}_${row.reference_number}`
  }));

  const filteredPlateData = plateData.filter(row => row.current_stage !== PLATE_TYPE.INSTALLATION);

  const data = [...orderData, ...filteredPlateData];
  const rows = [...orderRows, ...plateRows.filter(row => row.current_stage !== PLATE_TYPE.INSTALLATION)];

  const setRows = [...getUniqueGroups(data), ...rows.map((order: Partial<Order>) => createGroupInfoObjects(order))];
  return (
    <Paper className={classes.root}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <Grid
          key={setRows.length}
          getRowId={row => row.rootId || row.parentId}
          rows={setRows} columns={columns}>
          <ReferenceNumberFormatterProvider for={['order_reference_number']} />
          <SearchState />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <TreeDataState />
          <CustomTreeData getChildRows={getChildRows} />

          <IntegratedFiltering />
          <IntegratedPaging />
          <Toolbar />
          <SearchPanel />
          <RowDetailState />

          <Table cellComponent={Cell} rowComponent={MetaDataTableRow} columnExtensions={desktopColumnsWidth} />
          <TableHeaderRow />
          {!isDesktop && (<TableRowDetail contentComponent={getContentComponent} />)}
          {isDesktop && (<TableTreeColumn for="order_reference_number" />)}
          <PagingPanel />
        </Grid>
      )}
    </Paper>
  );
}
