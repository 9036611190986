import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import moment from 'moment';
require('moment-business-time'); 

dayjs.extend(utc);

moment.locale('en', {
    week: { dow: 1, doy: 1 },
    workinghours: {
        0: null,
        1: ['08:00:00', '16:00:00'],
        2: ['08:00:00', '16:00:00'],
        3: ['08:00:00', '16:00:00'],
        4: ['08:00:00', '16:00:00'],
        5: ['08:00:00', '16:00:00'],
        6: null
    },
    holidays : [
        // Permanent Holidays
        '*-01-01', // New Year's Day
        '*-05-01', // Labour Day
        '*-05-17', // Constitution Day
        '*-12-25', // Christmas Day
        '*-12-26', // St. Stephen's Day
        
        // Floating Holidays
        '2023-04-13', // 2023 Maundy Thursday
        '2023-04-14', // 2023 Good Friday
        '2023-05-25', // 2023 Ascension Day
        '2023-06-05', // 2023 Whit Monday

        '2024-04-07', // 2024 Maundy Thursday
        '2024-04-08', // 2024 Good Friday
        '2024-05-16', // 2024 Ascension Day
        '2024-05-27', // 2024 Whit Monday
        
        '2025-03-30', // 2025 Maundy Thursday
        '2025-03-31', // 2025 Good Friday
        '2025-05-15', // 2025 Ascension Day
        '2025-05-26', // 2025 Whit Monday
        
        '2026-04-19', // 2026 Maundy Thursday
        '2026-04-20', // 2026 Good Friday
        '2026-05-04', // 2026 Ascension Day
        '2026-05-16', // 2026 Whit Monday
        
        '2027-04-11', // 2027 Maundy Thursday
        '2027-04-12', // 2027 Good Friday
        '2027-05-20', // 2027 Ascension Day
        '2027-06-01', // 2027 Whit Monday
        
        '2028-03-26', // 2028 Maundy Thursday
        '2028-03-27', // 2028 Good Friday
        '2028-05-11', // 2028 Ascension Day
        '2028-05-22', // 2028 Whit Monday
        
        '2029-04-05', // 2029 Maundy Thursday
        '2029-04-06', // 2029 Good Friday
        '2029-05-31', // 2029 Ascension Day
        '2029-06-11', // 2029 Whit Monday
        
        '2030-03-28', // 2030 Maundy Thursday
        '2030-03-29', // 2030 Good Friday
        '2030-05-16', // 2030 Ascension Day
        '2030-05-27'  // 2030 Whit Monday
    ]
});

export function getFutureDateOrToday(inputDate: string | Date) {
    const today = moment();
    const date = moment(inputDate);
    const result = date.isValid() && date.isSameOrAfter(today) ? date : today;
    return result.toDate();
}

export function addBusinessDays(startDate: string | Date, daysToAdd: number) {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
        currentDate = currentDate.add(1, 'days');

        // @ts-ignore
        if (currentDate.isBusinessDay()) {
            addedDays++;
        }
    }

    return currentDate.toDate();
}

export const formatToDate = (date: string | Date) => dayjs(new Date(date)).format('DD-MM-YYYY');
export const formatToTime = (date: string | Date) => dayjs(new Date(date)).format('HH:mm');

export const formatToDateWithTime = (date?: string | Date) => dayjs(date).format('DD-MM-YYYY HH:mm');

export const formatToSaveDate = (date?: string | Date) => dayjs(date).format('YYYY-MM-DD');

export const formatToSaveUtcDate = (date?: string | Date) => dayjs.utc(date).format('YYYY-MM-DD');
export const formatToSaveUtcTime = (time?: string | Date) => dayjs.utc(time).format('HH:mm');

export function formatHours(amount: number = 0) {
    const days = Math.floor(amount/8);
    const hours = amount%8;

    const value = `${days}D${hours}H`;
    const color = amount > 12 ? "red" : "green";

    return { value, color };
  }
  
