import { STAGE, SPECIAL_COMPONENTS } from "./WizardEnums";
import { StepType } from "./WizardTypes";

export enum InstallationSteps {
  PRE_INSTALLATION = "PRE_INSTALLATION",
  PLATES_INSTALLED = "PLATES_INSTALLED",
  EQUIPMENT_INSTALLED = "EQUIPMENT_INSTALLED",
  SILICON = "SILICON",
  DAMAGE = "DAMAGE",
  GO_BACK = "GO_BACK",
  AFTER_INSTALLATION = "AFTER_INSTALLATION",
}

export enum PreInstallationStages {
  DAMAGE_CHECK = "DAMAGE_CHECK",
  TAKE_DAMAGE_PICTURE = "TAKE_DAMAGE_PICTURE",
  CABINET_PICTURE = "CABINET_PICTURE",
  SIDECABINETS_SIDEWALLS_PICTURE = "SIDECABINETS_SIDEWALLS_PICTURE",
  FLOOR_PICTURE = "FLOOR_PICTURE",
  KITCHEN_OVERVIEW_PICTURE_PRE = "KITCHEN_OVERVIEW_PICTURE_PRE",
}

export enum EquipmentInstalledStages {
  COOKTOP_MOUNTED = "COOKTOP_MOUNTED",
  COOKTOP_PICTURE = "COOKTOP_PICTURE",
  COOKTOP_NOT_INSTALLED = "COOKTOP_NOT_INSTALLED",
  SINK_MOUNTED = "SINK_MOUNTED",
  SINK_PICTURE = "SINK_PICTURE",
  SINK_NOT_INSTALLED = "SINK_NOT_INSTALLED",
  EL_CONTACT_MOUNTED = "EL_CONTACT_MOUNTED",
  EL_CONTACT_PICTURE = "EL_CONTACT_PICTURE",
  EL_CONTACT_NOT_INSTALLED = "EL_CONTACT_NOT_INSTALLED",
}

export enum SiliconStages {
  SILICON_USED = "SILICON_USED",
  SILICON_TYPE = "SILICON_TYPE",
  SILICON_WALL_CABINETS = "SILICON_WALL_CABINETS",
  SILICON_WALL_CABINETS_TYPE = "SILICON_WALL_CABINETS_TYPE",
  SILICON_WALL_CABINETS_WARNING = "SILICON_WALL_CABINETS_WARNING"
}

export enum DamageStages {
  DAMAGE_MADE = "DAMAGE_MADE",
  DAMAGE_LOCATION = "DAMAGE_LOCATION",
  STONE_DAMAGE_CHECK = "DAMAGE_STONE_DAMAGE_CHECK",
  STONE_DAMAGE_DESCRIPTION = "DAMAGE_STONE_DAMAGE_DESCRIPTION",
}

export enum GoBackStages {
  RETURN_NEEDED = "RETURN_NEEDED",
  RETURN_ELEMENT = "RETURN_ELEMENT",
}

export enum AfterInstallationStages {
  KITCHEN_RESET = "KITCHEN_RESET",
  KITCHEN_NOT_RESET_REASON = "KITCHEN_NOT_RESET_REASON",
  CABIN_PICTURE_POST = "CABIN_PICTURE_POST",
  SIDECABINETS_SIDEWALLS_PICTURE_POST = "SIDECABINETS_SIDEWALLS_PICTURE_POST",
  GIFT_BOX_PICTURE = "GIFT_BOX_PICTURE",
  FLOOR_PICTURE_POST = "FLOOR_PICTURE_POST",
  STONE_DETAIL_PICTURE = "STONE_DETAIL_PICTURE",
  KITCHEN_OVERVIEW_PICTURE_POST = "KITCHEN_OVERVIEW_PICTURE_POST",
  JOINTS_DETAIL_PICTURE = "JOINTS_DETAIL_PICTURE",
}

export const InstallationWizardQuestions: StepType[] = [
  {
    id: InstallationSteps.PRE_INSTALLATION,
    label: "Pre-Installation",
    stages: [
      {
        id: PreInstallationStages.DAMAGE_CHECK,
        type: STAGE.BOOL,
        label: "Can you find any damage on kitchen sidepanel, walls or floors?",
        isTrue: PreInstallationStages.TAKE_DAMAGE_PICTURE,
        isFalse: PreInstallationStages.CABINET_PICTURE
      },
      {
        id: PreInstallationStages.TAKE_DAMAGE_PICTURE,
        type: STAGE.FILE,
        label: "Take picture of the damage - 1 close and 1 from aprox 2m distance",
        isConfirm: PreInstallationStages.CABINET_PICTURE,
        uploadFilesLabel: "Picture",
      },
      {
        id: PreInstallationStages.CABINET_PICTURE,
        type: STAGE.FILE,
        label: "Picture of each cabinet from 1m distance",
        isConfirm: PreInstallationStages.SIDECABINETS_SIDEWALLS_PICTURE,
        alwaysShow: true,
        uploadFilesLabel: "Picture",
      },
      {
        id: PreInstallationStages.SIDECABINETS_SIDEWALLS_PICTURE,
        type: STAGE.FILE,
        label: "Pictures of sidecabinets and sidewalls",
        isConfirm: PreInstallationStages.FLOOR_PICTURE,
        alwaysShow: true,
        uploadFilesLabel: "Picture",
      },
      {
        id: PreInstallationStages.FLOOR_PICTURE,
        type: STAGE.FILE,
        label: "Picture of floor where you gonne lift the stone - from entrance to kitchen",
        isConfirm: PreInstallationStages.KITCHEN_OVERVIEW_PICTURE_PRE,
        alwaysShow: true,
        uploadFilesLabel: "Picture",
      },
      {
        id: PreInstallationStages.KITCHEN_OVERVIEW_PICTURE_PRE,
        type: STAGE.FILE,
        label: "Overview picture of the kitchen",
        alwaysShow: true,
        uploadFilesLabel: "Picture",
      }
    ]
  },
  {
    id: InstallationSteps.PLATES_INSTALLED,
    label: "Plates installed",
    stages: [],
    specialComponent: SPECIAL_COMPONENTS.PlatesCheckbox
  },
  {
    id: InstallationSteps.EQUIPMENT_INSTALLED,
    label: "Equipment installed",
    stages: [
      {
        id: EquipmentInstalledStages.COOKTOP_MOUNTED,
        type: STAGE.BOOL,
        label: "Is the cooktop mounted?",
        isTrue: EquipmentInstalledStages.COOKTOP_PICTURE,
        isFalse: EquipmentInstalledStages.COOKTOP_NOT_INSTALLED
      },
      {
        id: EquipmentInstalledStages.COOKTOP_PICTURE,
        type: STAGE.FILE,
        label: "Take 4 picture of the cooktop in each corner",
        isConfirm: EquipmentInstalledStages.SINK_MOUNTED,
        uploadFilesLabel: "Upload",
      },
      {
        id: EquipmentInstalledStages.COOKTOP_NOT_INSTALLED,
        type: STAGE.TEXT,
        label: "Why can't the cooktop be installed?",
        multiline: true,
        isConfirm: EquipmentInstalledStages.SINK_MOUNTED
      },
      {
        id: EquipmentInstalledStages.SINK_MOUNTED,
        type: STAGE.BOOL,
        label: "Is the sink mounted?",
        isTrue: EquipmentInstalledStages.SINK_PICTURE,
        isFalse: EquipmentInstalledStages.SINK_NOT_INSTALLED
      },
      {
        id: EquipmentInstalledStages.SINK_PICTURE,
        type: STAGE.FILE,
        label: "Take 4 picture of the sink in each corner",
        isConfirm: EquipmentInstalledStages.EL_CONTACT_MOUNTED,
        uploadFilesLabel: "Upload",
      },
      {
        id: EquipmentInstalledStages.SINK_NOT_INSTALLED,
        type: STAGE.TEXT,
        label: "Why can't the sink be installed?",
        multiline: true,
        isConfirm: EquipmentInstalledStages.EL_CONTACT_MOUNTED
      },
      {
        id: EquipmentInstalledStages.EL_CONTACT_MOUNTED,
        type: STAGE.BOOL,
        label: "Is the el-contact mounted?",
        isTrue: EquipmentInstalledStages.EL_CONTACT_PICTURE,
        isFalse: EquipmentInstalledStages.EL_CONTACT_NOT_INSTALLED
      },
      {
        id: EquipmentInstalledStages.EL_CONTACT_PICTURE,
        type: STAGE.FILE,
        label: "Take 4 picture of the sink el-contact in each corner",
        uploadFilesLabel: "Upload",
        isConfirm: null
      },
      {
        id: EquipmentInstalledStages.EL_CONTACT_NOT_INSTALLED,
        type: STAGE.TEXT,
        label: "Why can't the el-contact be installed?",
        multiline: true,
        isConfirm: null,
      }
    ]
  },
  {
    id: InstallationSteps.SILICON,
    label: "Silicon",
    stages: [
      {
        id: SiliconStages.SILICON_USED,
        type: STAGE.BOOL,
        label: "Have you used silicon on joints?",
        isTrue: SiliconStages.SILICON_TYPE,
        isFalse: SiliconStages.SILICON_WALL_CABINETS
      },
      {
        id: SiliconStages.SILICON_TYPE,
        type: STAGE.TEXT,
        label: "Which type of silicon is used?",
        editable: true,
        isConfirm: SiliconStages.SILICON_WALL_CABINETS,
        uploadFiles: true,
        uploadFilesLabel: "Upload"
      },
      {
        id: SiliconStages.SILICON_WALL_CABINETS,
        type: STAGE.BOOL,
        label: "Did you put silicon to the wall/side cabinets?",
        isTrue: SiliconStages.SILICON_WALL_CABINETS_TYPE,
        isFalse: SiliconStages.SILICON_WALL_CABINETS_WARNING
      },
      {
        id: SiliconStages.SILICON_WALL_CABINETS_TYPE,
        type: STAGE.TEXT,
        label: "Which type of silicon is used?",
        editable: true,
        isConfirm: null,
        uploadFiles: true,
        uploadFilesLabel: "Upload"
      },
      {
        id: SiliconStages.SILICON_WALL_CABINETS_WARNING,
        type: STAGE.WARN,
        isSubQuestion: true,
        label: "In the confirmation its ordered silicon to wall. Be sure this should not be done.",
        isConfirm: null,
      }
    ]
  },
  {
    id: InstallationSteps.DAMAGE,
    label: "Damage",
    stages: [
      {
        id: DamageStages.DAMAGE_MADE,
        type: STAGE.BOOL,
        label: "Did we make any damage on kitchen, side panel, walls or floor?",
        isTrue: DamageStages.DAMAGE_LOCATION,
        isFalse: DamageStages.STONE_DAMAGE_CHECK
      },
      {
        id: DamageStages.DAMAGE_LOCATION,
        type: STAGE.TEXT,
        label: "Where is the damage?",
        multiline: true,
        isConfirm: DamageStages.STONE_DAMAGE_CHECK,
        uploadFiles: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: DamageStages.STONE_DAMAGE_CHECK,
        type: STAGE.BOOL,
        label: "Is it any scratches or damage in the stone after installation?",
        isTrue: DamageStages.STONE_DAMAGE_DESCRIPTION,
        isFalse: null
      },
      {
        id: DamageStages.STONE_DAMAGE_DESCRIPTION,
        type: STAGE.TEXT,
        label: "Description and upload pictures of scratches/damage",
        multiline: true,
        isConfirm: null,
        uploadFiles: true,
        uploadFilesLabel: "Upload",
        isSubQuestion: true,
      }
    ]
  },
  {
    id: InstallationSteps.GO_BACK,
    label: "Go back",
    stages: [
      {
        id: GoBackStages.RETURN_NEEDED,
        type: STAGE.BOOL,
        label: "Do we have to go back to this place to finish some work?",
        isTrue: GoBackStages.RETURN_ELEMENT,
        isFalse: null
      },
      {
        id: GoBackStages.RETURN_ELEMENT,
        type: STAGE.TEXT,
        label: "Elements",
        multiline: true,
        multiple: true,
        uploadFiles: true,
        uploadFilesLabel: "Upload",
        isSubQuestion: true,
        isConfirm: null
      }
    ]
  },
  {
    id: InstallationSteps.AFTER_INSTALLATION,
    label: "After installation",
    stages: [
      {
        id: AfterInstallationStages.KITCHEN_RESET,
        type: STAGE.BOOL,
        label: "Is everything on the kitchen putted backed as it was before you started?",
        isTrue: AfterInstallationStages.CABIN_PICTURE_POST,
        isFalse: AfterInstallationStages.KITCHEN_NOT_RESET_REASON
      },
      {
        id: AfterInstallationStages.KITCHEN_NOT_RESET_REASON,
        type: STAGE.TEXT,
        label: "Why could you not put everything back?",
        multiline: true,
        isConfirm: AfterInstallationStages.CABIN_PICTURE_POST,
        isSubQuestion: true,
      },
      {
        id: AfterInstallationStages.CABIN_PICTURE_POST,
        type: STAGE.FILE,
        label: "Picture of each cabinet from 1m distance",
        isConfirm: AfterInstallationStages.SIDECABINETS_SIDEWALLS_PICTURE_POST,
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: AfterInstallationStages.SIDECABINETS_SIDEWALLS_PICTURE_POST,
        type: STAGE.FILE,
        label: "Pictures of side cabinets and sidewalls",
        isConfirm: AfterInstallationStages.GIFT_BOX_PICTURE,
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: AfterInstallationStages.GIFT_BOX_PICTURE,
        type: STAGE.FILE,
        label: "Picture of the gift box",
        isConfirm: AfterInstallationStages.FLOOR_PICTURE_POST,
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: AfterInstallationStages.FLOOR_PICTURE_POST,
        type: STAGE.FILE,
        label: "Picture of floor where you gonna lift the stone - from entrance to kitchen",
        isConfirm: AfterInstallationStages.STONE_DETAIL_PICTURE,
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: AfterInstallationStages.STONE_DETAIL_PICTURE,
        type: STAGE.FILE,
        label: "Detailed Picture of the stone from 1m distance",
        isConfirm: AfterInstallationStages.KITCHEN_OVERVIEW_PICTURE_POST,
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: AfterInstallationStages.KITCHEN_OVERVIEW_PICTURE_POST,
        type: STAGE.FILE,
        label: "Overview picture of the kitchen",
        isConfirm: AfterInstallationStages.JOINTS_DETAIL_PICTURE,
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      },
      {
        id: AfterInstallationStages.JOINTS_DETAIL_PICTURE,
        type: STAGE.FILE,
        label: "Detailed pictures of all joints",
        alwaysShow: true,
        uploadFilesLabel: "Upload",
      }
    ]
  }
];


export function setWarningAtList(list: StepType[], hasFuging: boolean) {
  list.forEach(step => {
    if (step.id === InstallationSteps.SILICON) {
      step.stages.forEach(stage => {
        if (stage.id === SiliconStages.SILICON_WALL_CABINETS_WARNING) {
          stage.type = hasFuging ? STAGE.WARN : STAGE.NONE;
        }
      })
    }
  })
}
