import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { MyDialog } from '../Dialog/MyDialog';
import { MyDialogUser } from '../Dialog/MyDialogUser';
import { useUserInput } from '../../hooks/useUserInput';
import { movePlateBackward } from '../../redux/actions/plateActions';
import { moveOrderBackward } from '../../redux/actions/ordersActions';
import { useIsAdmin } from '../../containers/withIsAdmin';
import { ErrorMessage } from '../Common/ErrorMessage';
import { useErrorHandler } from '../../hooks/useErrorHandler';

type DataTableInformationBack = {
  plate_id?: number
  order_id?: number
  title: string,
  current_stage?: string,
  actionType?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

export function DataTableInformationBack({ plate_id, order_id, title, current_stage, actionType }: DataTableInformationBack) {
  const isAdmin = useIsAdmin();
  const { onUserChange, user } = useUserInput();
  const { error, isUserError, handleError, resetError } = useErrorHandler();
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (!isAdmin) {
    return null;
  }

  const onSubmit = async () => {
    try {
      if (plate_id) {
        await dispatch(movePlateBackward({ plate_id, order_id, user_id: user, current_stage }));
      } else {
        await dispatch(moveOrderBackward({ order_id, user_id: user, actionType }));
      }
    } catch (err) {
      handleError(err);
      throw err;
    }
  };

  return (
    <div className={classes.root}>
      <MyDialog
        dialogTitle={title}
        size="medium"
        color="primary"
        buttonLabel="Move Back"
        modalSize="xs"
        variant="outlined"
        onSubmit={onSubmit}
      >
        <div>
          <Typography variant="subtitle2" className={classes.title}>
            Are you sure that you want to move that {plate_id ? 'Plate' : 'Order'} backward?
          </Typography>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
          <ErrorMessage message={error} isVisible={!!error} />
        </div>
      </MyDialog>
    </div>
  );
}
