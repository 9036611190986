import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from 'material-ui-pickers';
import { Theme, Typography } from '@material-ui/core';
import { ErrorMessage } from '../../components/Common/ErrorMessage';

import { MyDialog } from '../../components/Dialog/MyDialog';
import { InlineDictionarySelect } from "../../components/Orders/InlineDictionarySelect";

import { MyDialogUser } from '../../components/Dialog/MyDialogUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { useUserInput } from '../../hooks/useUserInput';
import { pickupDate } from '../../redux/actions/ordersActions';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import { formatToSaveDate, formatToDate, formatToTime, addBusinessDays } from '../../services/DateFormatter';

type FreightConfirmDateDialogType = {
  orderId: number,
  deliveryDate: Date,
  deliveryMethod: string,
  staticDeliveryMethod: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    gap: 10
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    "& > div": {
      margin: theme.spacing(1),
    }
  }
}));

export function FreightConfirmDateDialog({ orderId, deliveryDate, deliveryMethod, staticDeliveryMethod }: FreightConfirmDateDialogType) {
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();
  const classes = useStyles();

  const { onUserChange, user, resetUser } = useUserInput();
  const { error, handleError, resetError, isUserError } = useErrorHandler();

  const transportCompanies = useSelector((state: GlobalStoreType) => state.dictionary.transportCompaniesDictionary);
  const [selectedCompanyId, setCompany] = React.useState('');
  const submitTransportCompany = (value: string) => setCompany(value);

  const deliveryMethods = useSelector((state: GlobalStoreType) => state.dictionary.deliveryMethodsDictionary);
  const [selectedMethod, setMethod] = React.useState(deliveryMethod || null);
  const submitDeliveryMethod = (value: string) => setMethod(value);

  const [_date, changeDate] = React.useState(deliveryDate);

  const onChangeDate = async (newDate: Date) => changeDate(newDate);

  function resetLocalState() {
    resetUser();
  }

  async function submitPickupDate() {
    try {
      const date = formatToSaveDate(_date);

      const conditionalTime = formatToTime(_date);
      const daysAfter = conditionalTime < "12:00" ? 5 : 6;
      const minInstallationDate = addBusinessDays(_date, daysAfter);

      await pickupDate(orderId, {
        date,
        minInstallationDate,
        transportCompanyId: selectedCompanyId,
        deliveryMethod: staticDeliveryMethod || selectedMethod,
        user_id: user
      });
      resetLocalState();
    } catch (err) {
      handleError(err);
      throw err;
    }
  }

  React.useEffect(() => {
    changeDate(deliveryDate);
  }, [deliveryDate]);

  React.useEffect(() => {
    resetError();
  }, [user]);

  if (!(isAdmin || isMonter)) {
    return null;
  }

  return (
    <MyDialog
      buttonLabel={formatToDate(_date)}
      dialogTitle="Confirm date for pick up"
      color={'secondary'}
      variant={'outlined'}
      onSubmit={submitPickupDate}
      modalSize="xs"
    >
      <div className={classes.root}>
        <InlineDictionarySelect
          label="Transport Company"
          onChange={submitTransportCompany}
          dictionary={transportCompanies}
          value={selectedCompanyId}
        />
        <InlineDictionarySelect
          label="Packing Method"
          onChange={submitDeliveryMethod}
          dictionary={deliveryMethods}
          value={staticDeliveryMethod || selectedMethod}
          disabled={!!staticDeliveryMethod}
        />
        <DatePicker
          fullWidth
          variant="outlined"
          label="Pick up date"
          value={_date}
          minDate={deliveryDate}
          onChange={onChangeDate}
          format="dd-MM-yyyy"
        />
        <div className={classes.row}>
          <MyDialogUser onChange={onUserChange} userError={isUserError} user={user} />
        </div>
        <ErrorMessage message={error} isVisible={!!error} />
      </div>
    </MyDialog>
  );
}
