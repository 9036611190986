import { useState } from "react";
import { uploadFiles } from "../services/FileService";

export function useFileUploader(fileSettings: FileUploadSettings) {
  const INITIAL_STATE: FileType[] = [];
  const [filesState, setFiles] = useState(INITIAL_STATE);

  const prepareFileName = ({ name }: File) => {
    // Check if the file is from camera (typically named image.jpg)
    if (name.toLowerCase() === 'image.jpg') {
      // Create a timestamp-based name for camera images
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      return `${fileSettings.prependName}/${timestamp}-image.jpg`;
    }
    return `${fileSettings.prependName}/${name}`;
  };

  function prepareFiles(evt: HTMLInputEvent): FileType[] {
    const arrayFiles: File[] = Array.from(evt.target.files as ArrayLike<File>);
    return arrayFiles.map((file: File) => ({
      fileContent: file,
      name: file.name.toLowerCase() === 'image.jpg' 
        ? `${new Date().toISOString().replace(/[:.]/g, '-')}-image.jpg` 
        : file.name,
      key: prepareFileName(file),
      file_type: fileSettings.type
    }) as any);
  }

  async function sendToAws(files = filesState) {
    await uploadFiles(files, fileSettings);
  }

  async function handleSetFilesAndSend(evt: HTMLInputEvent) {
    const currentFiles = filesState;
    const preparedFiles = prepareFiles(evt);
    setFiles(preparedFiles);
    try {
      await sendToAws(preparedFiles);
    } catch (err) {
      setFiles(currentFiles);
      throw err;
    }
  }

  function resetState() {
    setFiles(INITIAL_STATE);
  }

  return { handleSetFilesAndSend, resetState, filesState };
}
