import * as React from 'react';
import { Paper, Theme, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import {
  RowDetailState,
  SearchState,
  FilteringState, Filter,
  IntegratedFiltering, PagingState, IntegratedPaging,
  TreeDataState, CustomTreeData,
  DataTypeProvider,
  SortingState, IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  Table,
  SearchPanel,
  Toolbar, PagingPanel,
  TableTreeColumn
} from '@devexpress/dx-react-grid-material-ui';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIsAdmin, useIsMonter } from '../withIsAdmin';
import { useSelector } from 'react-redux';

import { getColumns } from './MetaDataColumnsProvider';
import { getContentComponent } from './MetaDataContentComponentProvider';
import { Cell, OrderReferenceNumberCell } from './MetaDataTableCell';
import { MetaDataTableRow } from './MetaDataTableRow';
import { sortRows } from './MetaDataSortingFunctions';
import { LockOrientationContext } from '../../components/LockOrientation';
import { PageTitle } from '../../components/Page/PageTitle';
import { FilterOrders } from '../../components/Filters/FilterOrders';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  invoice: {
    position: 'absolute',
    margin: 10,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'contents',
      "& legend": {
        marginTop: 5,
        marginLeft: 10
      },
      "& > div": {
        marginTop: 5,
        marginLeft: 20
      }
    }
  },
}));

const getChildRows = (row: any, rootRows: any) => {
  const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.rootId : null));
  return childRows.length ? childRows : null;
};

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getUnique = (arr: any[], comp: string) => arr
  .map(e => e[comp])
  .map((e, i, final) => final.indexOf(e) === i && i)
  .filter((e: any) => arr[e])
  .map((e: any) => arr[e]);

const getUniqueGroups = (rows: Order[]) => {
  const groupedOrders = groupBy(rows, 'reference_number');
  const groups = Object.keys(groupedOrders).map(key => ({
    count: groupedOrders[key].length,
    key: key
  }));

  const uniqueRows = getUnique(rows, 'reference_number')
    .map(elem => ({
      ...elem, ...groups
        .find(c => c.key === elem.reference_number)
    }));

  return uniqueRows.map((element: Order) => ({
    ...element,
    parentId: null,
    rootId: element.reference_number,
    comments: element.comments
  }));
};

const createGroupInfoObjects = (elem: Order) => ({
  isExtraInfo: true,
  noDeliveries: true,
  parentId: elem.reference_number,
  deliveryAddress: elem.deliveryAddress,
  materialStatus: elem.materialStatus,
  totalWeight: elem.totalWeight,
  sellerTeams: elem.sellerTeams,
  cutouts: elem.cutouts,
  craneRequired: elem.history && elem.history['Crane required'],
  extraHelpForLift: elem.history && elem.history['Extra help required to lift'],
  estimatedInstallationTime: elem.history && elem.history['Estimated Installation Time'],
  stepStatuses: elem.stepStatuses
});

const ReferenceNumberFormatter = (props: any) => (
  <OrderReferenceNumberCell {...props.row} />
);

const ReferenceNumberFormatterProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={ReferenceNumberFormatter}
    {...props}
  />
);

type MetaDataTableType = {
  title: string,
  pageSubtitle?: React.ReactNode,
  rows: any[]
  contentComponent?: React.ComponentType<TableRowDetail.ContentProps>,
  actionType: ActionType,
  customAction?: JSX.Element,
  checkpoints?: CheckpointType[]
  disableSorting?: boolean,
  noMoveBackward?: boolean,
  allowDeleteOrder?: boolean,
  addExtraInfo?: boolean,
  withFlag?: boolean,
  getRowId?: (row: any, index: number) => number | string,
};

export function MetaDataTable({
                                title,
                                pageSubtitle,
                                rows,
                                actionType,
                                customAction,
                                checkpoints = [],
                                disableSorting = false,
                                noMoveBackward,
                                allowDeleteOrder,
                                addExtraInfo,
                                withFlag,
                              }: MetaDataTableType) {
  const classes = useStyles();
  const isAdmin = useIsAdmin();
  const isMonter = useIsMonter();
  const isCompleted = actionType === "completed";
  const theme = useTheme<Theme>();
  const isDesktopMedia = useMediaQuery(theme.breakpoints.up('sm'));
  const { isDesktopDevice, isMediumDevice } = React.useContext(LockOrientationContext);

  const filterColumns = (array: any[]) => array.filter(elem => elem.adminOnly ? isAdmin : true);

  let isDesktop = isDesktopMedia;

  if (isDesktopDevice) {
    isDesktop = true;
  }

  if (isMediumDevice) {
    isDesktop = false;
  }

  const { mobileColumns, mobileColumnsWidth, desktopColumns, desktopColumnsWidth } = getColumns(actionType);
  const columns = isDesktop ? desktopColumns : mobileColumns;
  const columnsWidth = isDesktop ? desktopColumnsWidth : (mobileColumnsWidth || []);

  const sellerTeams = useSelector((state: GlobalStoreType) => state.sellerTeams);
  const accountSellerTeams = useSelector((state: GlobalStoreType) => state.account.sellerTeams || false);
  const deliveryMethodsDictionary = useSelector((state: GlobalStoreType) => state.dictionary.deliveryMethodsDictionary);
  const deliveryMethods = deliveryMethodsDictionary.reduce((acc, { text, value }) => ({
    ...acc,
    [value]: text
  }), {} as Record<number, string>);

  if (sellerTeams.allMeasuringTeams.length > 0 || sellerTeams.allInstallationTeams.length > 0) {
    rows.forEach(row => {
      if (row.sellerTeams?.measuringId) {
        const measuringTeam = sellerTeams.allMeasuringTeams.find(team => team._id === row.sellerTeams.measuringId);
        row.sellerTeams.measuringName = measuringTeam ? measuringTeam.name : "<no match team>";
      }

      if (row.sellerTeams?.installationId) {
        const installationTeam = sellerTeams.allInstallationTeams.find(team => team._id === row.sellerTeams.installationId);
        row.sellerTeams.installationName = installationTeam?.name ?? "<no match team>";
        row.sellerTeams.installationAddDays = installationTeam?.addDays ?? 2;
        row.sellerTeams.autoConfirmPickupDate = installationTeam?.autoConfirmPickupDate ?? false;
        row.sellerTeams.staticPackingMethodKey = installationTeam?.staticPackingMethodKey;
      }
    })
  }

  if (isAdmin) {
    const selectedMeasuringTeams = sellerTeams.selMeasuringTeams;
    const selectedInstallationTeams = sellerTeams.selInstallationTeams;

    if (selectedMeasuringTeams.length > 0 || selectedInstallationTeams.length > 0) {
      const filteredRows = rows.filter(row => {
        const isMeasuringTeamRow = selectedMeasuringTeams.some(teamId => row.sellerTeams?.measuringId === teamId);
        if (isMeasuringTeamRow) {
          return true;
        }

        const isInstallationTeamRow = selectedInstallationTeams.some(teamId => row.sellerTeams?.installationId === teamId);
        if (isInstallationTeamRow) {
          return true;
        }

        return false;
      });

      rows = [...filteredRows];
    }
  } else if (accountSellerTeams) {
    const filteredRows = rows.filter(row => {
      if (row.sellerTeams.measuringId === accountSellerTeams.measuringId) {
        return true;
      }

      if (row.sellerTeams.installationId === accountSellerTeams.installationId) {
        return true;
      }

      return false;
    });

    rows = [...filteredRows];
  }

  const data = sortRows(rows
    .map((
      // @ts-ignore
      {
        material: { name, type, thickness, need_impregnation } = {
          name: '',
          type: '',
          thickness: '',
          need_impregnation: false
        }, ...rest
      }: Order | Plate = {}) => ({
      ...rest,
      name,
      type,
      thickness,
      need_impregnation,
      checkpoints,
      actionType,
      customAction,
      noMoveBackward,
      allowDeleteOrder,
      withFlag
    })), disableSorting);

  enum INVOICE_STATE {
    ALL = "2",
    WITH = "1",
    WITHOUT = "0"
  }

  const [stateInvoice, setStateInvoice] = React.useState(INVOICE_STATE.ALL);
  const handleStateInvoice = (event: any) => {
    setStateInvoice(event.target.value);
  };

  const setRows = addExtraInfo
    ? [...getUniqueGroups(data), ...rows.map((order: Order) => createGroupInfoObjects(order))]
    : data.filter((item: any) => stateInvoice === INVOICE_STATE.ALL
      || (stateInvoice === INVOICE_STATE.WITH && item.is_invoiced)
      || stateInvoice === INVOICE_STATE.WITHOUT && !item.is_invoiced
    );

  const compareDate = (a: Date, b: Date) => {
    if (a === b) {
      return 0;
    } else if (a === null && b !== null) {
      return -1;
    } else if (a !== null && b === null) {
      return 1;
    }
    return (a < b) ? -1 : 1;
  };

  const [integratedSortingColumnExtensions] = React.useState([
    { columnName: 'installation_date', compare: compareDate }
  ]);

  const customFilterPredicate = (value: any, filter: Filter): boolean => {
    if (!filter.value || typeof filter.value !== 'string') {
      return false;
    }

    const deliveryMethodText = deliveryMethods[value] || 'Unknown';
    return deliveryMethodText.toLowerCase().includes(filter.value.toLowerCase());
  };

  return (
    <React.Fragment>
      <PageTitle title={title} />
      {!!pageSubtitle && pageSubtitle}
      <Paper className={classes.root}>
        {isCompleted && (
          <div className={classes.container}>
            <FormControl component="fieldset" className={classes.invoice}>
              <FormLabel component="legend">Invoice</FormLabel>
              <RadioGroup row aria-label="invoice" value={stateInvoice} onChange={handleStateInvoice}>
                <FormControlLabel value="2" control={<Radio />} label="ALL" />
                <FormControlLabel value="1" control={<Radio />} label="with" />
                <FormControlLabel value="0" control={<Radio />} label="without" />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <Grid
          getRowId={(row) => row.id || row.parentId}
          rows={setRows}
          columns={filterColumns(columns)}
        >
          <ReferenceNumberFormatterProvider for={['reference_number']} />
          <SearchState />
          <PagingState defaultCurrentPage={0} pageSize={30} />
          <TreeDataState />
          {addExtraInfo && (<CustomTreeData getChildRows={getChildRows} />)}

          <SortingState
            defaultSorting={[{ columnName: 'installation_date', direction: 'desc' }]}
          />
          {(actionType === "completed") && <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />}

          <FilteringState />
          <IntegratedFiltering
            columnExtensions={[{ columnName: 'delivery_method', predicate: customFilterPredicate }]} />
          <IntegratedPaging />
          <Toolbar />
          <SearchPanel />
          {(isAdmin || isMonter) && !isCompleted && <FilterOrders />}
          <RowDetailState />

          {addExtraInfo && (<CustomTreeData getChildRows={getChildRows} />)}
          <Table cellComponent={Cell} rowComponent={MetaDataTableRow} columnExtensions={columnsWidth} />
          <TableHeaderRow showSortingControls />
          {!isDesktop && (<TableRowDetail contentComponent={getContentComponent} />)}
          {addExtraInfo && isDesktop && (<TableTreeColumn for="reference_number" />)}
          <PagingPanel />
        </Grid>
      </Paper>
    </React.Fragment>
  );

}
