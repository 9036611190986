import * as React from 'react';
import { useDispatch } from 'react-redux';
import { formatToDate, formatToTime } from '../../services/DateFormatter';

import { MyDialog } from '../../components/Dialog/MyDialog';
import { completeOrder } from '../../redux/actions/ordersActions';
import CheckIcon from '@material-ui/icons/Check';

import { fetchCurrentFilesForRecord } from '../../redux/files/FileActions';

import { ORDER_TYPE, PLATE_TYPE } from '../../shared/constants';
import {
  EquipmentInstalledStages,
  InstallationSteps,
  InstallationWizardQuestions,
  setWarningAtList
} from '../../shared/WizardStep/InstallationWizardQuestions';
import { WizardStep } from '../../components/WizardStep/WizardStep';

import { WarningInfo } from '../../components/WarningInfo';
import { InstallationEstimateDateDialog } from './InstallationEstimateDateDialog';
import { InstallationConfirmDateDialog } from './InstallationConfirmDateDialog';
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { SPECIAL_COMPONENTS } from "../../shared/WizardStep/WizardEnums";
import { APIClient } from "../../services/apiService";

type InstallationCompleteOrderType = {
  reference_number: string,
  orderId: number,
  plates: Plate[],
  files: FileType[],
  isInfo: boolean,
  pickup_date: Date,
  conditionalDate: Date,
  installation_date: Date,
  installation_confirmed: boolean
};

export function InstallationCompleteOrder({
                                            reference_number,
                                            orderId,
                                            isInfo,
                                            plates,
                                            pickup_date,
                                            conditionalDate,
                                            installation_date,
                                            installation_confirmed
                                          }: InstallationCompleteOrderType) {
  const dispatch = useDispatch();
  const { handleError } = useErrorHandler();

  const [userId, setUserId] = React.useState<number>();

  const getInstallationInfo = React.useCallback(async () => {
    const response = await APIClient.get(`orders/${reference_number}/installationInfo`);
    setWarningAtList(list, response.data.isFuging);
  }, [reference_number]);

  async function submitInstallationOrder() {
    try {
      const checkedArray = Object.keys(platesChecked);
      await completeOrder(orderId, {
        user_id: userId,
        comment: "",
        plates: checkedArray.filter(key => platesChecked[key])
      });
    } catch (err) {
      // @ts-ignore
      handleError(err);
      throw err;
    }
  }

  const [allStepsCompleted, setAllStepsCompleted] = React.useState(false);

  const onStepsCompleted = (value: boolean) => {
    setAllStepsCompleted(value);
  }

  const onSubmit = () => {
    if (allStepsCompleted && anyPlateChecked) {
      submitInstallationOrder();
    }
  }

  const [platesChecked, setPlatesChecked] = React.useState({} as any);

  const onChangeCheckbox = (value: number) => {
    setPlatesChecked({
      ...platesChecked,
      [value]: !platesChecked[value]
    })
  };

  //legacy: old flow before 2025
  const oldFlow = plates.some(plate => plate.has_freight === null);
  //----------------------------

  const [warningNoFreight, setWarningNoFreight] = React.useState('');

  const getExtraData = (spec: SPECIAL_COMPONENTS) => {
    const noFreightList = plates.reduce((acc, { current_stage, has_freight, reference_number }) => {
      if ((current_stage === PLATE_TYPE.INSTALLATION) && !has_freight) {
        acc.push(reference_number);
      }

      return acc;
    }, []);

    const textNoFreight = noFreightList.length > 0
      ? `Freight is not confirmed for plates [${noFreightList.join(", ")}] - installation confirmation is not possible`
      : '';
    setWarningNoFreight(textNoFreight);

    const plates_checked = Object.fromEntries(
      plates.map(plate => [plate.id, platesChecked[plate.id] || plate.current_stage === PLATE_TYPE.COMPLETED])
    );
    const plates_disabled = Object.fromEntries(
      plates.map(plate => [plate.id, plate.has_freight === false || plate.current_stage !== PLATE_TYPE.INSTALLATION])
    );

    switch (spec) {
      case SPECIAL_COMPONENTS.PlatesCheckbox:
        return {
          plates,
          onChangeCheckbox,
          isChecked: plates_checked,
          isDisabled: plates_disabled
        }

      default:
        return {};
    }
  }

  const dateBreakTime = <div>{formatToDate(installation_date)}<br />{formatToTime(installation_date)}</div>;

  const list = React.useMemo(() => {
    const uniquePlateTypes: Set<string> = new Set();
    plates.forEach(item => {
      if (!item.types) {
        return;
      }

      item.types.forEach(type => {
        uniquePlateTypes.add(type.plate_type);
      });
    });

    const result = Array.from(uniquePlateTypes);
    return InstallationWizardQuestions.map(e => {
      if (e.id === InstallationSteps.EQUIPMENT_INSTALLED) {
        return {
          ...e,
          stages: e.stages.filter(stage => {
            switch (stage.id) {
              case EquipmentInstalledStages.COOKTOP_PICTURE:
              case EquipmentInstalledStages.COOKTOP_MOUNTED:
              case EquipmentInstalledStages.COOKTOP_NOT_INSTALLED:
                return result.some(e => e.includes('KOKETOP'));

              case EquipmentInstalledStages.EL_CONTACT_PICTURE:
              case EquipmentInstalledStages.EL_CONTACT_MOUNTED:
              case EquipmentInstalledStages.EL_CONTACT_NOT_INSTALLED:
                return result.some(e => e.includes('EL-KONTAKT'));

              case EquipmentInstalledStages.SINK_PICTURE:
              case EquipmentInstalledStages.SINK_MOUNTED:
              case EquipmentInstalledStages.SINK_NOT_INSTALLED:
                return result.some(e => e.includes('VASK'));

              default:
                return true;
            }
          })
        }
      }

      return e;
    })
  }, [plates]);

  const anyPlateChecked = plates.some(plate => platesChecked[plate.id])

  const specHasError = React.useMemo(() => ({
    [SPECIAL_COMPONENTS.PlatesCheckbox]: Object.values(platesChecked).filter(Boolean).length === 0,
  }), [platesChecked])

  React.useEffect(() => {
    dispatch(fetchCurrentFilesForRecord(orderId, ORDER_TYPE.INSTALLATION));
  }, []);

  if (!installation_date && !oldFlow) {
    return (
      <InstallationEstimateDateDialog
        orderId={orderId}
        conditionalDate={conditionalDate}
      />
    )
  }

  if (!installation_confirmed && !oldFlow) {
    return (
      <InstallationConfirmDateDialog
        orderId={orderId}
        plates={plates}
        referenceNumber={reference_number}
        conditionalDate={conditionalDate}
        pickupDate={pickup_date}
      />
    )
  }

  return (
    <MyDialog
      buttonLabel={dateBreakTime}
      icon={oldFlow ? <CheckIcon /> : null} //legacy: old flow before 2025
      color="primary"
      customColor={isInfo ? 'orange' : ''}
      dialogTitle={reference_number}
      onEnter={getInstallationInfo}
      onSubmit={onSubmit}
      onSubmitClose
      onSubmitDisabled={!allStepsCompleted || !anyPlateChecked}
      cancelButtonText="Close"
      modalSize="xl"
      minHeight={650}
      isStepper={true}
    >
      {({ onClose }: any) => (
        <>
          {!oldFlow && warningNoFreight && <WarningInfo>{warningNoFreight}</WarningInfo>}
          <WizardStep
            orderType={ORDER_TYPE.INSTALLATION}
            orderId={orderId}
            stepList={list}
            onStepsCompleted={onStepsCompleted}
            onUserIdChange={setUserId}
            onClose={onClose}
            getExtraData={getExtraData}
            specHasError={specHasError}
          />
        </>
      )}
    </MyDialog>
  );
}
